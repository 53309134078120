<h1 mat-dialog-title>{{data.title}}</h1>
<div *ngIf="payment">
  <div *ngIf="payment.internal_status && payment.internal_status !== null &&
    payment.internal_status.toLowerCase() === 'failed'">
    Your card verification failed. Please contact us for more inforamtion.
    <div mat-dialog-actions style="float: right;margin-top: 10px;">
      <a mat-raised-button href="/contact" style="margin-right: 10px;">Contact Us
      </a>
      <button mat-raised-button color="warn" (click)="onNoClick()">{{ data.btnCancelText }}</button>
    </div>
  </div>
  <div class="mt-1" *ngIf="!payment.internal_status || (payment.internal_status && payment.internal_status !== null &&
    payment.internal_status.toLowerCase() === 'pending')">
    <h3>You are charged two small amount on this card. </h3>
    <h3>Please check your card transactions and enter those two amount to
      verify your card.</h3>
    <h5 class="mb-1">Note : Enter amount between 0.01 to 0.99</h5>
    <form [formGroup]="verifyCardForm">
      <div fxLayout="row wrap">
        <mat-form-field fxFlex="100" fxFlex.gt-sm="50" class="px-1">
          <input matInput placeholder="Amount 1" formControlName="amount1" required>
          <mat-error *ngIf="verifyCardForm.controls.amount1.errors?.required">Amount 1 is
            required</mat-error>
          <!-- <mat-error *ngIf="verifyCardForm.controls.amount1.hasError('minlength')">Amount 1 isn't
            long enough, minimum of 4 digit</mat-error>
          <mat-error *ngIf="verifyCardForm.controls.amount1.hasError('maxlength')">Amount 1
            reaches max length, maximum 4 digit</mat-error> -->
          <mat-error *ngIf="!verifyCardForm.controls.amount1.hasError('required')
                                 && !verifyCardForm.controls.amount1.hasError('minlength')
                                 && !verifyCardForm.controls.amount1.hasError('maxlength')
                                && verifyCardForm.controls.amount1.hasError('pattern')">
            Please enter amount between 0.01 to 0.99</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="100" fxFlex.gt-sm="50" class="px-1">
          <input matInput placeholder="Amount 2" formControlName="amount2" required>
          <mat-error *ngIf="verifyCardForm.controls.amount2.errors?.required">Amount 2 is
            required</mat-error>
          <!-- <mat-error *ngIf="verifyCardForm.controls.amount2.hasError('minlength')">Amount 2 isn't
            long enough, minimum of 4 digit</mat-error>
          <mat-error *ngIf="verifyCardForm.controls.amount1.hasError('maxlength')">Amount 2
            reaches max length, maximum 4 digit</mat-error> -->
          <mat-error *ngIf="!verifyCardForm.controls.amount2.hasError('required')
                                 && !verifyCardForm.controls.amount2.hasError('minlength')
                                 && !verifyCardForm.controls.amount2.hasError('maxlength')
                                && verifyCardForm.controls.amount2.hasError('pattern')">
            Please enter amount between 0.01 to 0.99</mat-error>
        </mat-form-field>
      </div>
      <div mat-dialog-actions style="float: right;margin-top: 10px;">
        <button mat-raised-button color="primary" (click)="onClick()" cdkFocusInitial>{{ data.btnOkText }}</button>
        <button mat-raised-button color="warn" (click)="onNoClick()">{{ data.btnCancelText }}</button>
      </div>
    </form>

  </div>
</div>