export class SubMenuItemViewModel {
    item: string;
    link: string;
    product_data?:MegamenuViewModel[]=[]
}

export class MenuItemViewModel {
    item_title: string;
    link: string;
    menu_order: number;
    menu_type: string;
    data: SubMenuItemViewModel[] = [];
}


export class MegamenuViewModel{
    brand: string;
    image: string;
    item: string;
    seo: string;

}