import { Component, Input, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogDataViewModel } from 'src/models/viewmodel/dialog-data.viewmodel';

@Component({
  selector: 'app-display-dialog',
  templateUrl: './display-dialog.component.html',
})
export class DisplayDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataViewModel) { }
}
