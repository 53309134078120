import { ErrorHandler, Injectable } from '@angular/core';
import { LocalStoregesService } from './localstorage.service';
import { ApiService } from './api.service';
import { CustomErrorRequest } from 'src/models/dto/custom-error.request';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
    constructor(private _localStorage: LocalStoregesService,
        private apiService: ApiService) { }
    handleError(error) {
        // your custom error handling logic
        try {
            if (error && error.toString() !== '{}') {
                this._localStorage.setLog(error.toString());
                const request: CustomErrorRequest = {
                    text: 'Custom' + error.toString()
                };
                this.apiService.logError(request).subscribe(result => { });
            }
        } catch (e) {
            console.log(e);
            const request: CustomErrorRequest = {
                text: e.toString()
            };
            this.apiService.logError(request).subscribe(result => { });
        }
    }
}
