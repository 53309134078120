export abstract class BaseMapper {
    /**
     * Maps the basic properties other than objects and arrays.
     * @param source An object to map the data from.
     * @param target An object to map the data to.
     */
    protected static autoMap(source: any, target: any) {
        if (source && target && !Array.isArray(source) && !Array.isArray(target)) {
            Object.keys(source).forEach((key) => {
                if (this.canMap(source[key])) {
                    target[key] = source[key];
                }
            });
        }
    }

    private static canMap(value: any) {
        return typeof value === 'undefined' || value === null || typeof value !== 'object';
    }
}
