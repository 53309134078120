import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { SidenavMenuService } from '../sidenav-menu/sidenav-menu.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    providers: [SidenavMenuService]
})
export class BreadcrumbComponent {

    public breadcrumbs: any[];
    public isShow = true;

    /**
     * @class DetailComponent
     * @constructor
     */
    constructor(
        private activatedRoute: ActivatedRoute,
        public router: Router, private titleService: Title
    ) {
        this.breadcrumbs = [];
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // set breadcrumbs
                this.breadcrumbs = [];
                const root: ActivatedRoute = this.activatedRoute.root;
                this.breadcrumbs = this.getBreadcrumbs(root);
            }
        });
    }

    private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: any[] = []): any[] {
        const ROUTE_DATA_BREADCRUMB = 'breadcrumbItem';
        // get the child routes
        const children: ActivatedRoute[] = route.children;

        // return if there are no more children
        if (children.length === 0) {
            return breadcrumbs;
        }

        // iterate over each children
        for (const child of children) {
            // verify primary route
            if (child.outlet !== PRIMARY_OUTLET) {
                continue;
            }

            // verify the custom data property "breadcrumb" is specified on the route
            if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
                return this.getBreadcrumbs(child, url, breadcrumbs);
            }

            // get the route's URL segment
            const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

            // append route URL to URL
            url += `/${routeURL}`;

            // add breadcrumb
            const breadCrumb = child.snapshot.data[ROUTE_DATA_BREADCRUMB];

            const breadcrumb: any = {
                label: breadCrumb.paramKey ?
                    (breadCrumb.breadcrumbName ? breadCrumb.breadcrumbName + ',' : '') +
                    child.snapshot.params[breadCrumb.paramKey] :
                    breadCrumb.breadcrumbName,
                params: child.snapshot.params,
                url: url
            };

            const title = breadCrumb.paramKey ?
                breadCrumb.title + ' - ' + child.snapshot.params[breadCrumb.paramKey] : breadCrumb.title;
            this.titleService.setTitle('StockNSell - ' + title);
            breadcrumbs.push(breadcrumb);
            this.isShow = breadCrumb && breadCrumb.isShow !== undefined ? breadCrumb.isShow : true;
            // recursive
            return this.getBreadcrumbs(child, url, breadcrumbs);
        }
    }

    getLabelArray(labels: string) {
        return labels ? labels.split(',') : [];
    }

}
