import { BaseMapper } from '../base.mapper';
import { CurrencyConversionResponse } from 'src/models/dto/currencyconversion.response';
import { CurrencyConversionViewModel } from 'src/models/viewmodel/currencyconversion.viewmodel';

export class CurrencyConversionMenuMapper extends BaseMapper {
    public static mapToModel(response: CurrencyConversionResponse): CurrencyConversionViewModel {
        const model: CurrencyConversionViewModel = new CurrencyConversionViewModel();
        this.autoMap(response, model);
        return model;
    }

    public static mapToModels(responses: CurrencyConversionResponse[]): CurrencyConversionViewModel[] {
        const models: CurrencyConversionViewModel[] = [];
        if (responses) {
            for (const response of responses) {
                models.push(this.mapToModel(response));
            }
        }
        return models;
    }
}
