/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./breadcrumb.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i7 from "@angular/material/card";
import * as i8 from "../sidenav-menu/sidenav-menu.service";
import * as i9 from "./breadcrumb.component";
import * as i10 from "@angular/platform-browser";
var styles_BreadcrumbComponent = [i0.styles];
var RenderType_BreadcrumbComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BreadcrumbComponent, data: {} });
export { RenderType_BreadcrumbComponent as RenderType_BreadcrumbComponent };
function View_BreadcrumbComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "breadcrumb-item"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeSubMenus() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 737280, null, 0, i4.LayoutDirective, [i5.MediaMonitor, i1.ElementRef, i5.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(3, 737280, null, 0, i4.LayoutAlignDirective, [i5.MediaMonitor, i1.ElementRef, [6, i4.LayoutDirective], i5.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "breadcrumb-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Home"]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 1, 0, currVal_2); var currVal_3 = "row"; _ck(_v, 2, 0, currVal_3); var currVal_4 = "start center"; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BreadcrumbComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, _v.parent.context.$implicit.url, _v.parent.context.$implicit.params); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.label; _ck(_v, 3, 0, currVal_3); }); }
function View_BreadcrumbComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "breadcrumb-title"], ["style", "max-height: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_BreadcrumbComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "breadcrumb-title active"], ["style", "max-height: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_BreadcrumbComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "breadcrumb-item"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"], ["style", "max-height: 50px;"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i4.LayoutDirective, [i5.MediaMonitor, i1.ElementRef, i5.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 737280, null, 0, i4.LayoutAlignDirective, [i5.MediaMonitor, i1.ElementRef, [6, i4.LayoutDirective], i5.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_7)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_8)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_v.parent.context.index == (_co.breadcrumbs.length - 1)) && (_v.context.index != (_co.getLabelArray(_v.parent.context.$implicit.label).length - 1))); _ck(_v, 4, 0, currVal_2); var currVal_3 = ((_v.parent.context.index == (_co.breadcrumbs.length - 1)) && (_v.context.index == (_co.getLabelArray(_v.parent.context.$implicit.label).length - 1))); _ck(_v, 6, 0, currVal_3); }, null); }
function View_BreadcrumbComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "breadcrumb-item"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"], ["style", "max-height: 50px;"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i4.LayoutDirective, [i5.MediaMonitor, i1.ElementRef, i5.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 737280, null, 0, i4.LayoutAlignDirective, [i5.MediaMonitor, i1.ElementRef, [6, i4.LayoutDirective], i5.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_5)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_6)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_v.context.index != (_co.breadcrumbs.length - 1)); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.getLabelArray(_v.context.$implicit.label); _ck(_v, 6, 0, currVal_3); }, null); }
function View_BreadcrumbComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["fxFlex", "100"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i4.FlexDirective, [i5.MediaMonitor, i1.ElementRef, [3, i4.LayoutDirective], i5.StyleUtils, i5.LAYOUT_CONFIG], { flex: [0, "flex"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "mat-card", [["class", "breadcrumb light-block mat-card"], ["fxLayout", "row"]], null, null, null, i6.View_MatCard_0, i6.RenderType_MatCard)), i1.ɵdid(3, 737280, null, 0, i4.LayoutDirective, [i5.MediaMonitor, i1.ElementRef, i5.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(4, 49152, null, 0, i7.MatCard, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BreadcrumbComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BreadcrumbComponent_4)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "100"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "row"; _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.router.url != "/"); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.breadcrumbs; _ck(_v, 8, 0, currVal_3); }, null); }
function View_BreadcrumbComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container"], ["fxLayout", "row wrap"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i4.LayoutDirective, [i5.MediaMonitor, i1.ElementRef, i5.StyleUtils], { layout: [0, "layout"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row wrap"; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.isShow === true); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BreadcrumbComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.router.url != "/"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BreadcrumbComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-breadcrumb", [], null, null, null, View_BreadcrumbComponent_0, RenderType_BreadcrumbComponent)), i1.ɵprd(4608, null, i8.SidenavMenuService, i8.SidenavMenuService, [i3.Location, i2.Router]), i1.ɵdid(2, 49152, null, 0, i9.BreadcrumbComponent, [i2.ActivatedRoute, i2.Router, i10.Title], null, null)], null, null); }
var BreadcrumbComponentNgFactory = i1.ɵccf("app-breadcrumb", i9.BreadcrumbComponent, View_BreadcrumbComponent_Host_0, {}, {}, []);
export { BreadcrumbComponentNgFactory as BreadcrumbComponentNgFactory };
