export const environment = {
  production: true,
  serviceUrl: 'https://qa.llh.wwr.mybluehost.me/services/',
  imageUrl: 'https://qa.llh.wwr.mybluehost.me/img/',
  domainName: 'qa.llh.wwr.mybluehost.me',
  documentLabelsUrl: 'https://qa.llh.wwr.mybluehost.me/doc/labels/',
  // serviceUrl: 'https://dev.llh.wwr.mybluehost.me/services/',
  // imageUrl: 'https://dev.llh.wwr.mybluehost.me/img/',
  // domainName: 'dev.llh.wwr.mybluehost.me',
  // documentLabelsUrl: 'https://stocknsell.com/doc/labels/',
};
