<div class="example-container" fxLayout="row">
    <form [formGroup]="mailForm">
        <div fxFlex="100" fxFlex.xs="50">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Enter Your Email</mat-label>
                <input matInput type="email" formControlName="email" required (change)="mailForm.controls.email.setValue(mailForm.controls.email.value.trim());
                            " (keydown.space)="$event.preventDefault();">
                <mat-error *ngIf="mailForm.controls.email.errors?.required">Email is required
                </mat-error>
                <mat-error *ngIf="mailForm.controls.email.hasError('maxlength')">Email
                    reaches max length, maximum 50 characters</mat-error>
                <mat-error *ngIf="mailForm.controls.email.hasError('invalidEmail')">
                    Invalid Email</mat-error>
            </mat-form-field>
            <p class="mat-error" [innerHTML]='error'></p>
        </div>
        <div fxFlex="100">
            <button button mat-raised-button class="w-100 buy-btn btn-effect" matTooltip="Subscribe Here"
                (click)="submitMailForm()">
                <span class="price-text">Subscribe</span>

            </button>
            <!-- <button  mat-raised-button  color="primary" style="height: 50px;">Subscribe</button> -->
        </div>
    </form>
    <div fxLayout="row">
        <div class="row" fxFlex="100">

        </div>
    </div>
</div>