/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./display-dialog.component";
var styles_DisplayDialogComponent = [];
var RenderType_DisplayDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DisplayDialogComponent, data: {} });
export { RenderType_DisplayDialogComponent as RenderType_DisplayDialogComponent };
export function View_DisplayDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 5, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""], ["style", "float: right;margin-top: 10px;"]], null, null, null, null, null)), i0.ɵdid(9, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 3, "button", [["cdkFocusInitial", ""], ["color", "primary"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12).dialogRef.close(i0.ɵnov(_v, 12).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(11, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i0.ɵdid(12, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(13, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_7 = "primary"; _ck(_v, 11, 0, currVal_7); var currVal_8 = _co.data.data; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.message; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.data.htmlContent; _ck(_v, 7, 0, currVal_3); var currVal_4 = (i0.ɵnov(_v, 11).disabled || null); var currVal_5 = (i0.ɵnov(_v, 11)._animationMode === "NoopAnimations"); var currVal_6 = i0.ɵnov(_v, 12).ariaLabel; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6); var currVal_9 = _co.data.btnOkText; _ck(_v, 13, 0, currVal_9); }); }
export function View_DisplayDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-display-dialog", [], null, null, null, View_DisplayDialogComponent_0, RenderType_DisplayDialogComponent)), i0.ɵdid(1, 49152, null, 0, i7.DisplayDialogComponent, [i1.MatDialogRef, i1.MAT_DIALOG_DATA], null, null)], null, null); }
var DisplayDialogComponentNgFactory = i0.ɵccf("app-display-dialog", i7.DisplayDialogComponent, View_DisplayDialogComponent_Host_0, {}, {}, []);
export { DisplayDialogComponentNgFactory as DisplayDialogComponentNgFactory };
