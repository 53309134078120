import { ApiService } from 'src/services/api.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Inject
} from '@angular/core';
import { HeaderMenuViewModel } from 'src/models/viewmodel/headermenu.viewmodel';
import { LocalStoregesService } from '../../../services/localstorage.service';
import { WebStorageService, LOCAL_STORAGE } from 'angular-webstorage-service';
import { Router } from '@angular/router';
import { SubMenuItemViewModel } from 'src/models/viewmodel/menuitem.viewmodel';
export interface PeriodicElement {
 
  position: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: "Mobile"},
  {position: "Television"},
  {position: "Audio"},

];
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input()
  headerMenus: HeaderMenuViewModel[] = [];

  @Input()
  currencies: string[] = [];

  @Output() onChangeCurrency: EventEmitter<any> = new EventEmitter();

  public currency: string;
  userName: string;
  customerName: string;
  displayedColumns: string[] = ['position'];
  dataSource = ELEMENT_DATA;
  megaMenuArray:SubMenuItemViewModel;
  currencydisable = false
  constructor(private api_service:ApiService,private _localStoregeService: LocalStoregesService, private router: Router,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService) {

  }
  ngOnInit() {
    if (this.currencies && this.currencies.length > 0) {
      const localCurrencyDetail = this._localStoregeService.getCurrencyDetail();
      this.currency = localCurrencyDetail && localCurrencyDetail.currency ? localCurrencyDetail.currency : this.currencies[0];
    }
    this._localStoregeService.watchStorage().subscribe(() => {
      const localCurrencyDetail = this._localStoregeService.getCurrencyDetail();
      this.currency = localCurrencyDetail && localCurrencyDetail.currency ? localCurrencyDetail.currency : this.currencies[0];
    });

this.api_service.disableCurrency.subscribe((data)=>{
  this.currencydisable = data
})
    if(this.headerMenus){
     for (let i = 0; i < this.headerMenus.length; i++) {
       if(this.headerMenus[i].data){
          for (let j = 0; j < this.headerMenus[i].data.length; j++) {
               if(this.headerMenus[i].data[j].product_data && this.headerMenus[i].data[j].product_data.length > 0){
                this.megaMenuArray = this.headerMenus[i].data[0]
                this.onMegaMenuData(this.megaMenuArray)
               }
                   
            }
         }
      
      }
    }
}

 

  getCustomerName() {
    return this.storage.get('customer_name');
  }

  getUserName() {
    return this.storage.get('customer_username');
  }

  isLoggedIn() {
    return this.storage.get('auth_token') ? true : false;
  }
  public changeCurrency(currency) {
    this.currency = currency;
    this.onChangeCurrency.emit(currency);
  }

  openMegaMenu() {
    const pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
      if (el.children.length > 0) {
        if (el.children[0].classList.contains('mega-menu')) {
          el.classList.add('mega-menu-pane');
        }
      }
    });
  }

  openMenu(el) {
    el.currentTarget.click();
  }

  navigateToLogin() {
    if (this.router.url.includes('sign-in', 0)) {
      window.location.reload();
    } else {
      this.router.navigateByUrl('/sign-in');
    }
  }




  onMegaMenuData(submenu){
    this.megaMenuArray = null
if(submenu.product_data){

  this.megaMenuArray = submenu
}

  }

}
