import { CurrencyDetailViewModel } from 'src/models/viewmodel/currencydetail.viewmodel';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable } from 'rxjs';

@Injectable()
export class LocalStoregesService {
    private changeCurrency: EventEmitter<any> = new EventEmitter();
    constructor(private localStorageService: LocalStorageService) { }

    watchStorage(): Observable<any> {
        return this.changeCurrency.asObservable();
    }

    public setLog(error: any) {
        const er = this.localStorageService.get('LOGERROR') + '\n';
        this.localStorageService.set('LOGERROR', er + error);
    }

    public setCurrencyDetail(currencyDetail: CurrencyDetailViewModel) {
        currencyDetail.displayCurrency = currencyDetail.currency === 'USD' ? 'US' : 'CA';
        this.localStorageService.set('CURRENCY', currencyDetail);
        this.changeCurrency.next('changed');
    }

    public setViewedProducts(viewedProducts: string) {
        this.localStorageService.set('VIEWEDPRODUCTS', viewedProducts);
        this.changeCurrency.next('changed');
    }

    public getViewedProducts(): string {
        return this.localStorageService.get('VIEWEDPRODUCTS');
    }

    public getCurrencyDetail(): CurrencyDetailViewModel {

        return this.localStorageService.get('CURRENCY');
    }

    public getIsInternational(): boolean {

        return this.localStorageService.get('ISINTERNATIONAL');
    }

    public setIsInternational(isInternational: boolean) {
        this.localStorageService.set('ISINTERNATIONAL', isInternational);
        this.changeCurrency.next('changed');
    }

    public getLiveSearchFilterStatus(): boolean {
        return this.localStorageService.get('LIVESEARCHFILTERSTATUS');
    }

    public setLiveSearchFilterStatus(status: boolean) {
        this.localStorageService.set('LIVESEARCHFILTERSTATUS', status);
        this.changeCurrency.next('changed');
    }
}

