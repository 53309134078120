import { BaseMapper } from '../base.mapper';
import { HeaderMenuViewModel } from 'src/models/viewmodel/headermenu.viewmodel';
import { MenuItemMapper } from './menuitem.mapper';
import { MenuItemResponse } from 'src/models/dto/menuitem.response';

export class HeaderMenuMapper extends BaseMapper {
    public static mapToModel(response: MenuItemResponse): HeaderMenuViewModel {
        return MenuItemMapper.mapToModel(response);
    }

    public static mapToModels(responses: MenuItemResponse[]): HeaderMenuViewModel[] {
        const models: HeaderMenuViewModel[] = [];
        if (responses) {
            return MenuItemMapper.mapToModels(responses);
        }
        return models;
    }
}
