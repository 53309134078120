import { menuScrollStrategy } from '../../utils/scroll-strategy';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';
var cookieConfig = {
    cookie: {
        domain: environment.domainName
    },
    palette: {
        popup: {
            background: '#efefef',
        },
        button: {
            background: '#689f38',
            text: 'white'
        }
    },
    position: 'bottom',
    theme: 'classic',
    layout: 'my-custom-layout',
    layouts: {
        'my-custom-layout': '{{messagelink}}{{compliance}}'
    },
    elements: {
        messagelink: "\n    <span id=\"cookieconsent:desc\" class=\"cc-message\">\n    <span>StockNSell.com uses cookies to improve user experience. By using our website you consent to our cookies in accordance with our <a href=\"/pages/privacy-policy\" target=\"_blank\">Cookie Policy</a>.</span>\n    </span>\n    ",
    },
    content: {
        message: '',
        // cookiePolicyLink: 'Cookie Policy',
        // cookiePolicyHref: 'https://cookie.com',
        // privacyPolicyLink: 'Privacy Policy',
        // privacyPolicyHref: '/pages/privacy-policy',
        // tosLink: 'Terms of Service',
        // tosHref: '/terms',
        dismiss: 'Close',
        deny: 'Refuse cookies',
        allow: 'Allow cookies'
    }
};
var ɵ0 = menuScrollStrategy;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
