import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { environment } from '../environments/environment';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';

@Injectable()
export class HttpService {
  constructor(private http: HttpClient, @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private snackBar: MatSnackBar) { }
  protected readonly URL_ROOT: string = environment.serviceUrl;
  protected readonly IMAGE_URL: string = environment.imageUrl;

  protected get<T>(actionUrl: string, loading = false): Observable<T> {
    try {
      if (this.storage.get('auth_token') ? true : false) {
        return this.http.get<T>(`${this.URL_ROOT}${actionUrl}`,
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
              'jwt': this.storage.get('auth_token')
            }),
            params: {
              'loader': loading.toString()
            }
          });
      } else {
        return this.http.get<T>(`${this.URL_ROOT}${actionUrl}`,
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            params: {
              'loader': loading.toString()
            }
          }
        );
      }
    } catch (ex) {
      this.handleError(ex);
    }
  }

  protected getWithToken<T>(actionUrl: string): Observable<T> {
    try {
      return this.http.get<T>(`${this.URL_ROOT}${actionUrl}`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'jwt': this.storage.get('auth_token')
          })
        });
    } catch (ex) {
      this.handleError(ex);
    }
  }

  protected post<S, T>(actionUrl: string, data: S, loading = false): Observable<T> {
    try {
      if (this.storage.get('auth_token') ? true : false) {
        return this.http.post<T>(`${this.URL_ROOT}${actionUrl}`, $.param(data),
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
              'jwt': this.storage.get('auth_token')
            }),
            params: {
              'loader': loading.toString()
            }
          });
      } else {
        return this.http.post<T>(`${this.URL_ROOT}${actionUrl}`, $.param(data),
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded'
            }),
            params: {
              'loader': loading.toString()
            }
          });
      }
    } catch (ex) {
      this.handleError(ex);
    }
  }

  protected postWithToken<S, T>(actionUrl: string, data: S, loading = false): Observable<T> {
    try {
      return this.http.post<T>(`${this.URL_ROOT}${actionUrl}`, $.param(data),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'jwt': this.storage.get('auth_token'),
          }),
          params: {
            'loader': loading.toString()
          }
        }
      );
    } catch (ex) {
      this.handleError(ex);
    }
  }

  protected uploadFile<S, T>(actionUrl: string, file: File): Observable<T> {
    try {
      let httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders.set('jwt', this.storage.get('auth_token'));
      httpHeaders.append('Content-Type', 'Application/json');

      const params = new FormData();
      params.append('files[]', file);

      return this.http.post<T>(`${this.URL_ROOT}${actionUrl}`, params, { headers: httpHeaders });
    } catch (ex) {
      this.handleError(ex);
    }
  }
  protected deleteWithToken<T>(actionUrl: string, loading = false): Observable<T> {
    try {
      return this.http.delete<T>(`${this.URL_ROOT}${actionUrl}`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'jwt': this.storage.get('auth_token'),
          }),
          params: {
            'loader': loading.toString()
          }
        }
      );
    } catch (ex) {
      this.handleError(ex);
    }
  }

  private handleError(error: Response | any) {
    console.error('ApiService::handleError', error);
    this.snackBar.open(error, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
  }
}

