var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseMapper } from '../base.mapper';
import { MegamenuViewModel } from 'src/models/viewmodel/menuitem.viewmodel';
var MegaMenuMapper = /** @class */ (function (_super) {
    __extends(MegaMenuMapper, _super);
    function MegaMenuMapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MegaMenuMapper.mapToModel = function (response) {
        var model = new MegamenuViewModel();
        this.autoMap(response, model);
        return model;
    };
    MegaMenuMapper.mapToModels = function (responses) {
        var models = [];
        if (responses) {
            for (var _i = 0, responses_1 = responses; _i < responses_1.length; _i++) {
                var response = responses_1[_i];
                models.push(this.mapToModel(response));
            }
        }
        return models;
    };
    return MegaMenuMapper;
}(BaseMapper));
export { MegaMenuMapper };
