import { ShopByCategoryViewModel } from "./shopbycategory.viewmodel";

export class HomeViewModel {
    slider: SliderViewModel[] = [];
    tab_view: TabViewModel;
    tab_label: TabLabelViewModel;
    lowest_ask: ProductViewModel;
    highest_bid: ProductViewModel;
    category: CategoryViewModel[];
    brand: BrandViewModel[];
    // shop_by_category:ShopByCategoryViewModel[]=[];
    shop_by_category:ShopByCategoryViewModel[]=[];
}



export class SliderViewModel {
    image: string;
    title: string;
    subtitle: string;
    bglink: string;
    btn1: string;
    btn1link: string;
    btn2: string;
    btn2link: string;
}

export class TabViewModel {
    featured_products: ProductViewModel;
    popular_products: ProductViewModel;
    new_products: ProductViewModel;
}

export class TabLabelViewModel {
    featured_products: string;
    popular_products: string;
    new_products: string;
}

export class ProductViewModel {
    title: string;
    product_list: ProductDescriptionViewModel[] = [];
}

export class ProductDescriptionViewModel {
    name: string;
    img: string;
    price: number;
    last_sale: number;
    seo: string;
    product_id: number;
    lowest_ask: number;
    lowest_ask_usd: number;
    lowest_ask_cad: number;
    highest_bid: number;
    highest_bid_usd: number;
    highest_bid_cad: number;
    hover:number;
}

export class CategoryViewModel {
    category: string;
    parentId: number;
}

export class BrandViewModel {
    brand_id: number;
    brand: string;
    brand_logo: string;
    description: string;
}
