import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchHeightDirective } from './match-height.directive';
import { TwoDigitDecimalNumberDirective } from './two-digit-decimal-number.directive';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        MatchHeightDirective,
        TwoDigitDecimalNumberDirective
    ],
    exports: [
        MatchHeightDirective,
        TwoDigitDecimalNumberDirective
    ]
})
export class DirectivesModule { }
