<div class="container" fxLayout="row wrap" *ngIf="router.url != '/'">
    <div fxFlex="100" *ngIf="isShow === true">
        <mat-card fxLayout="row" class="breadcrumb light-block">
            <!-- <a *ngIf="router.url != '/'" routerLink="/" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center" (click)="closeSubMenus()">
                <mat-icon>home</mat-icon>
                <span class="breadcrumb-title">Homepage</span>
            </a>
            <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center">                 
                <a [hidden]="i == (breadcrumbs.length - 1)" [routerLink]="[breadcrumb.url]">{{breadcrumb.name}}</a>   
                <span [hidden]="i != (breadcrumbs.length - 1)" class="breadcrumb-title active"><b>{{breadcrumb.name}}</b></span>
            </div>  -->
            <a *ngIf="router.url != '/'" routerLink="/" class="breadcrumb-item" fxLayout="row"
                fxLayoutAlign="start center" (click)="closeSubMenus()">
                <!-- <mat-icon>mat-icon>home</mat-icon> -->
                <span class="breadcrumb-title">Home</span>
            </a>
            <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" style="max-height: 50px;" class="breadcrumb-item" fxLayout="row"
            fxLayoutAlign="start center">                
                <!-- <a [routerLink]="[breadcrumb.url, breadcrumb.params]">{{ breadcrumb.label }}</a> -->
                            <a *ngIf="i != (breadcrumbs.length - 1)" 
                    [routerLink]="[breadcrumb.url, breadcrumb.params]">{{breadcrumb.label}}</a> 
                                                       
                <div *ngFor="let breadcrumbLabel of getLabelArray(breadcrumb.label); let j = index;"  style="max-height: 50px;"
                    class="breadcrumb-item" fxLayout="row"
                    fxLayoutAlign="start center">
                    <span class="breadcrumb-title" style="max-height: 50px;" *ngIf="i == (breadcrumbs.length - 1) && j != getLabelArray(breadcrumb.label).length -1">{{breadcrumbLabel}}</span>
                    <span class="breadcrumb-title active" style="max-height: 50px;" *ngIf="i == (breadcrumbs.length - 1) && j == getLabelArray(breadcrumb.label).length -1">{{breadcrumbLabel}}</span>
                 </div>
            </div>         
        </mat-card>
    </div>
</div>