import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Settings, AppSettings, MetaTagConfiguration } from '../../services/app.settings';
import { NgcCookieConsentService, NgcStatusChangeEvent, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading = false;
  public settings: Settings;
  private statusChangeSubscription: Subscription;
  cookieStatus = false;

  constructor(private ccService: NgcCookieConsentService, public appSettings: AppSettings, public titleService: Title,
    public router: Router, public cookieService: CookieService,
    public activatedRoute: ActivatedRoute, public location: Location,
    public metatag: MetaTagConfiguration) {
    const loc = <any>this.location.valueOf();
    const basePath = loc._platformStrategy._platformLocation.location.origin;

    this.metatag.setSocialMediaTags(basePath,
      'StockNSell Official Website | Get Offer Now',
      `Buy and Sell Electronics, Mobile, Laptop, Appliances, Gaming
       Console and Many More From Popular Brands like Apple, Samsung, LG, Breville and much more.`,
      'assets/images/logo/title_logo.png'
    );

    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh
    if (this.cookieService.check('cookieconsent_status') &&
      this.cookieService.get('cookieconsent_status') === 'dismiss') {
      this.cookieStatus = true;
    } else {
      this.cookieStatus = false;
      this.ccService.clearStatus();
      this.ccService.init(this.ccService.getConfig());
    }
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.status === 'dismiss') {
          this.cookieStatus = true;
        } else {
          this.cookieStatus = false;
        }
      });

    const appTitle = 'StockNSell';

    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        if (event instanceof NavigationEnd) {
          window.scrollTo(0, 0);
        }
        //  this.titleService.setTitle(`${appTitle}-${ttl}`);
      });


  }

  setCookiesTerm() {
    if (this.cookieStatus === true) {
      if (this.cookieService.check('cookieconsent_status') &&
        this.cookieService.get('cookieconsent_status') === 'dismiss') {
        this.cookieStatus = true;
        this.ccService.close(true);
      } else {
        this.cookieStatus = false;
        this.ccService.clearStatus();
        this.ccService.init(this.ccService.getConfig());
      }
    }
  }

  ngAfterViewInit() {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     window.scrollTo(0, 0);
    //   }
    // });
  }
  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.statusChangeSubscription.unsubscribe();
  }
}


