<mat-sidenav-container id="page-menu-item">
    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6 bg-skyblue">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
            <mat-icon color="warn">close</mat-icon>
        </button>
        <app-sidenav-menu *ngIf="sidenavMenuItems && sidenavMenuItems.length > 0" [menuItems]="sidenavMenuItems"
            [menuParentId]="0" [currencies]="currency" (onChangeCurrency)="changeCurrency($event)" isLoad=true>
        </app-sidenav-menu>
        <div class="divider"></div>
    </mat-sidenav>
    <mat-toolbar id="fixed_header_bar" class="sticky-bar"
        *ngIf="stickyHeader && stickyHeader.show && stickyHeader.show.toString() === '1'"
        [style.color]='stickyHeader.color' [style.font-size]='stickyHeader.font_size'
        [style.text-align]='stickyHeader.text_align' [style.font-weight]='stickyHeader.weight'
        [style.font-style]='stickyHeader.font_style' [style.background-color]='stickyHeader.background'>
        <mat-toolbar-row [style.margin]='stickyHeader.margin' [style.padding]='stickyHeader.padding'
            [ngStyle]="stickyHeader.style" [innerHTML]='stickyHeader.text | safeHtml'>
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-toolbar class="bg-skyblue">
        <mat-toolbar-row class="logo-toolbar theme-container content-center" style="background-color:#f6fafd;min-height: 93px;"  [ngStyle.xs]="{'min-height':'56px','height':'56px'}">
            <div class="header-logo text-center" *ngIf="['sm','md','lg','xl'] | fxIf | async">
                <a routerLink="/" (click)="closeSubMenus()">
                    <img src="assets/images/logo/header_logo2.png"  alt="StockNSell Logo">
                </a>
            </div>
            <div class="header-logo-mobile " *ngIf="['xs'] | fxIf | async">
                <a routerLink="/" (click)="closeSubMenus()">
                    <img src="assets/images/logo/header_logo2.png"  alt="StockNSell Logo">
                </a>
            </div>
            <div class="vl" *ngIf="['sm','md','lg','xl'] | fxIf | async"></div>
            <div class="web-info header-web-info"  *ngIf="['sm','md','lg','xl'] | fxIf | async"><span (click)="openVideoDialog()" class="web-info-span-main text-blue">New to StockNSell?</span> <br>
         
                <span (click)="openVideoDialog()" class="web-info-span2">
                    <mat-icon style="
                        font-size: 22px;
                        transform: translate(-0%, 32%);
                        width: 22px;
                        margin-right: 10px;
                        " class="text-red">play_circle_outline</mat-icon> <span  class="web-info-span-sub text-blue">Watch This Video To See <b>how it works</b></span>
                </span>
         
        </div>
            
               
            <div fxFlex *ngIf="['lg','xl'] | fxIf | async" style="padding: 5px;">
                <form class=" search-form header-search" fxLayout="row">
                    <button mat-raised-button [matMenuTriggerFor]="categoriesMenu"
                        #categoriesMenuTrigger="matMenuTrigger" type="button"
                        class="mat-elevation-z0 categories text-truncate" style="height: 50px;min-width: 160px;">{{category?.category}}<mat-icon>
                            arrow_drop_down</mat-icon></button>
                    <mat-menu #categoriesMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="categories-dropdown">
                        <span (mouseleave)="categoriesMenuTrigger.closeMenu()">
                            <app-category-list [categories]="categories" [categoryParentId]="0"
                                (change)="changeCategory($event)"></app-category-list>
                        </span>
                    </mat-menu>
                    <button mat-mini-fab 
                    (click)="trigger.closePanel();navigateToAllProduct(searchControl.value,category)" type="button"
                    class="search-btn mat-elevation-z0 text-muted" style="height: 50px;">
                    <mat-icon color="warn"  style="padding: 0px 12px; border-left: solid 1px rgba(151, 151, 151, 0.2);">search</mat-icon>
                </button>
                    <input matInput [matAutocomplete]="autoSearch" placeholder="Search Mobile, Laptop, Appliances, etc." [formControl]="searchControl" 
                    style="background-color: white;color: black;height: 50px;max-width: 599px;font-size: 14px; padding: 0 13px;" #trigger="matAutocompleteTrigger"
                        (keyup.enter)="trigger.closePanel();navigateToAllProduct(searchControl.value,category)">
                    <mat-autocomplete #autoSearch="matAutocomplete">
                        <mat-option *ngFor="let category of filteredOptions | async" [value]="category.title"
                            (click)="navigateToProduct(category);">
                            <span matTooltip="{{ category.title }}">{{ category.title }}</span>
                        </mat-option>
                        <mat-option *ngIf="searchControl.value" [value]="searchControl.value"
                            (click)="navigateToAllProduct(searchControl.value,category);">
                            <span matTooltip="View all in search list">View all in search list</span>
                        </mat-option>
                    </mat-autocomplete>
                   
                </form>



                
            </div>
            <div  *ngIf="['lg','xl'] | fxIf | async" style="color: black;max-width: 220px;" >
                <div class="float-right">
                    <span >
                        <a mat-button [routerLink]="['/wishlist']" routerLinkActive="router-link-active"  class="menuCSS" >
                            <mat-icon class="mat-icon-sm" style="margin-right: 5px;font-size: 25px; margin-right: 10px;">favorite_border</mat-icon>Wishlist
                        </a>
                    </span>
              
                  </div>
            </div>
            <div  *ngIf="['md'] | fxIf | async" style="color: black;width: 100%;" >
                <div class="float-right">
                    <span>
                        <a mat-button [routerLink]="['/wishlist']" routerLinkActive="router-link-active"  class="menuCSS" >
                            <mat-icon class="mat-icon-sm caret" style="margin-right: 10px;">favorite_border</mat-icon>Wishlist
                        </a>
                    </span>
              
                  </div>
            </div>
           
            <!--<div  *ngIf="['lg','xl'] | fxIf | async" style="color: black;" >
                <div class="float-right">
                    <app-menu *ngIf="headerMenus && currency" fxShow="false" fxShow.gt-sm [headerMenus]="headerMenus"
                    [currencies]="currency" (onChangeCurrency)="changeCurrency($event)"></app-menu> 
              
                  </div>
            </div>
            <div  *ngIf="['md'] | fxIf | async" style="color: black;width: 100%;" >
                <div class="float-right">
                    <app-menu *ngIf="headerMenus && currency" fxShow="false" fxShow.gt-sm [headerMenus]="headerMenus"
                    [currencies]="currency" (onChangeCurrency)="changeCurrency($event)"></app-menu> 
              
                  </div>
            </div>-->

            <div *ngIf="['sm','xs'] | fxIf | async"  class="header-right-bar">
                <!-- <div fxFlexOffset="10" fxLayout="row" fxLayoutAlign="center center" class="a123" style="z-index: 9999"> -->
                <!-- <div style="right:10px; position:absolute" *ngIf="['sm','xs'] | fxIf | async"> -->
                <!-- <div fxFlexOffset="10" fxLayout="row" fxLayoutAlign="center center" class="a123">
                <div fxFlexOffset="10" fxLayout="row" fxLayoutAlign="center center" class="a123" style="z-index: 9999">
                    <div fxLayoutAlign="center center" fxHide="false" fxHide.gt-sm>
                        <button mat-icon-button [matMenuTriggerFor]="searchMenu" #searchMenuTrigger="matMenuTrigger"
                            class="search-toggle-btn">
                            <mat-icon class="mat-icon-md">search</mat-icon>
                        </button>
                        <mat-menu #searchMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                            class="search-dropdown mat-elevation-z8">
                            <form method="get" fxFlex class="search-form">
                                <button mat-raised-button [matMenuTriggerFor]="categories2Menu"
                                    #categories2MenuTrigger="matMenuTrigger" type="button"
                                    class="mat-elevation-z0 categories text-muted" style="background-color:
                                    white!important;" (click)="stopClickPropagate($event)">{{category?.category}}
                                    <mat-icon>arrow_drop_down
                                    </mat-icon></button>
                                <mat-menu #categories2Menu="matMenu" [overlapTrigger]="false" xPosition="before"
                                    class="categories-dropdown">
                                    <span (mouseleave)="categories2MenuTrigger.closeMenu()">
                                        <app-category-list [categories]="categories" [categoryParentId]="0"
                                            (change)="changeCategory($event)"></app-category-list>
                                    </span>
                                </mat-menu>
                                <input matInput placeholder="Type to search..." fxFlex
                                    [matAutocomplete]="autoSearchSmall" [formControl]="searchControl"
                                    (click)="stopClickPropagate($event)" #trigger="matAutocompleteTrigger"
                                    (keyup.enter)="searchMenuTrigger.closeMenu();trigger.closePanel();navigateToAllProduct(searchControl.value,category)">
                                <mat-autocomplete #autoSearchSmall="matAutocomplete" fxFlex>
                                    <mat-option *ngFor="let category of filteredOptions | async"
                                        [value]="category.title"
                                        (click)="navigateToProduct(category);searchMenuTrigger.closeMenu()">
                                        <span matTooltip="{{ category.title }}">{{ category.title }}</span>
                                    </mat-option>
                                    <mat-option *ngIf="searchControl.value" [value]="searchControl.value"
                                        (click)="navigateToAllProduct(searchControl.value,category);">
                                        <span matTooltip="View all in search list">View all in search list</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <button mat-mini-fab (click)="searchMenuTrigger.closeMenu();trigger.closePanel();
                                    navigateToAllProduct(searchControl.value,category)" type="button"
                                    class="search-btn mat-elevation-z0 text-muted">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </form>
                        </mat-menu>
                    </div>
                </div> -->
                <span fxHide="false" fxHide.gt-xs *ngIf="!mobileSearchInput">
                    <button mat-button 
                    type="button" class="search-btn mat-elevation-z0 text-muted" (click)="mobileSearchInputShowMethod(false)" style="border-radius: 0px;">
                       <mat-icon>search</mat-icon>
                   </button>
                </span>
                <span fxHide="false" fxHide.gt-xs *ngIf="mobileSearchInput">
                    <button mat-button 
                    type="button" class="search-btn mat-elevation-z0 text-muted" (click)="mobileSearchInputShowMethod(true)" style="border-radius: 0px;">
                       <mat-icon>close</mat-icon>
                   </button>
                </span>
                <span (click)="openVideoDialog()" fxHide="false" fxHide.gt-xs *ngIf="!mobileSearchInput">
                    <button mat-button>
                        <mat-icon>play_circle_outline</mat-icon>
                    </button>
                </span>
                <span fxHide="false" fxHide.gt-sm *ngIf="!mobileSearchInput">
                    <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle">
                        <mat-icon>menu</mat-icon>
                    </button>
                </span>
            </div>
            
        </mat-toolbar-row>
    </mat-toolbar>
    <mat-toolbar style="height: 100%; background-color: #f6fafd;" *ngIf="(['xs'] | fxIf | async) && mobileSearchInput">
        <div  style="    background-color: #f6fafd;
    padding: 10px;">
        <form method="get" fxLayoutAlign="center center" class="search-form-fix" style="border-radius: 0px;">
            <div class="row">
                <div   class="col-sm-12">
                    <span style="font-family: 'Avenir Next'; font-size: 16px; font-stretch: normal; font-style: normal;line-height: normal;letter-spacing: -0.19px; color: #010344; font-weight: bold;">
                        Search
                    </span>
                    <button [matMenuTriggerFor]="categories2Menu"
                        #categories2MenuTrigger="matMenuTrigger" type="button"
                        class="categories" style=" font-family: 'Avenir Next'; font-size: 16px; font-stretch: normal; font-style: normal;line-height: normal;letter-spacing: -0.19px; color: #010344 !important;border: none;
                        background: transparent !important;"
                        (click)="stopClickPropagate($event)">{{category?.category}}
                        <mat-icon style="vertical-align: middle;">arrow_drop_down
                        </mat-icon></button>
                    <mat-menu #categories2Menu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="categories-dropdown">
                        <span (mouseleave)="categories2MenuTrigger.closeMenu()" style="">
                            <app-category-list [categories]="categories" [categoryParentId]="0"
                                (change)="changeCategory($event)">
                            </app-category-list>
                        </span>
                    </mat-menu>
                    
                </div>
                <div class="col-sm-12" style="border-bottom: solid 1px rgba(151, 151, 151, 0.2);">
                        <button mat-mini-fab (click)="trigger.closePanel();navigateToAllProduct(searchControl.value,category)"
                        type="button" class="search-btn mat-elevation-z0 text-muted" style="border-radius: 0px; margin-left:0px;text-align:left; background: transparent !important;">
                           <mat-icon>search</mat-icon>
                       </button>
                    <input style="padding-left: 6px;   font-family: 'Avenir Next';
                    font-weight: 500;
                    font-size: 12px;" matInput
                        placeholder="Type to search..." fxFlex="100" [matAutocomplete]="autoSearchSmall"
                        [formControl]="searchControl" (click)="stopClickPropagate($event)"
                        #trigger="matAutocompleteTrigger"
                        (keyup.enter)="trigger.closePanel();navigateToAllProduct(searchControl.value,category)">
                    <mat-autocomplete #autoSearchSmall="matAutocomplete" fxFlex>
                        <mat-option *ngFor="let category of filteredOptions | async" [value]="category.title"
                            (click)="navigateToProduct(category);">
                            <span matTooltip="{{ category.title }}">{{ category.title }}</span>
                        </mat-option>
                        <mat-option *ngIf="searchControl.value" [value]="searchControl.value"
                            (click)="navigateToAllProduct(searchControl.value,category);">
                            <span matTooltip="View all in search list">View all in search list</span>
                        </mat-option>
                    </mat-autocomplete>
                    
                </div>
            </div>
        </form>
    </div>
    </mat-toolbar>
    <!-- <div *ngIf="['xs'] | fxIf | async" style="background-color: #efefef;
    padding: 10px;    text-align: center;" >
 <span class="web-info-span-main text-blue">New to StockNSell ?</span> <br >
         
 <span (click)="openVideoDialog()" class="web-info-span2">
     <mat-icon style="color: #f44336;
         font-size: 22px;
         transform: translate(-0%, 28%);
         width: 17px;
         ">play_circle_outline</mat-icon><span  class="web-info-span-sub text-blue" style="padding-left: 5px;">      Watch This Video To See <b>how it works</b></span>
 </span>
    </div> -->
    <div *ngIf="['md','sm'] | fxIf | async" style="    background-color: #f6fafd;
    padding: 10px;">
        <form method="get" fxLayoutAlign="center center" class="search-form-fix" style="border-radius: 0px;">
            <div fxFlex="100">
                <div fxLayoutAlign="center center" fxFlex="100">
                    <button mat-raised-button [matMenuTriggerFor]="categories2Menu"
                        #categories2MenuTrigger="matMenuTrigger" type="button"
                        class="mat-elevation-z0 categories text-muted" style=" border-radius: 5%;
                        width: 199px;padding:0px!important;"
                        (click)="stopClickPropagate($event)">{{category?.category}}
                        <mat-icon>arrow_drop_down
                        </mat-icon></button>
                    <mat-menu #categories2Menu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="categories-dropdown">
                        <span (mouseleave)="categories2MenuTrigger.closeMenu()">
                            <app-category-list [categories]="categories" [categoryParentId]="0"
                                (change)="changeCategory($event)">
                            </app-category-list>
                        </span>
                    </mat-menu>
                    <div fxFlex="100">
                        <input style="padding-left: 6px;background-color: white;" matInput
                            placeholder="Type to search..." fxFlex="100" [matAutocomplete]="autoSearchSmall"
                            [formControl]="searchControl" (click)="stopClickPropagate($event)"
                            #trigger="matAutocompleteTrigger"
                            (keyup.enter)="trigger.closePanel();navigateToAllProduct(searchControl.value,category)">
                        <mat-autocomplete #autoSearchSmall="matAutocomplete" fxFlex>
                            <mat-option *ngFor="let category of filteredOptions | async" [value]="category.title"
                                (click)="navigateToProduct(category);">
                                <span matTooltip="{{ category.title }}">{{ category.title }}</span>
                            </mat-option>
                            <mat-option *ngIf="searchControl.value" [value]="searchControl.value"
                                (click)="navigateToAllProduct(searchControl.value,category);">
                                <span matTooltip="View all in search list">View all in search list</span>
                            </mat-option>
                        </mat-autocomplete>
                        <button mat-mini-fab (click)="trigger.closePanel();navigateToAllProduct(searchControl.value,category)"
                         type="button" class="search-btn mat-elevation-z0 text-muted" style="border-radius: 0px;">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="full-container bg-skyblue2">
    <app-menu class="bg-skyblue2" *ngIf="headerMenus && currency" fxShow="false" fxShow.gt-sm [headerMenus]="headerMenus"
        [currencies]="currency" (onChangeCurrency)="changeCurrency($event)"></app-menu>
    </div>
    <div class="full-container main" >
        <app-breadcrumb class="theme-container"></app-breadcrumb>       
     
        <router-outlet>

        </router-outlet>
    </div>
    <div class="area-effect hide">
        <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    </div>


    <app-footer [footer]="footer" [footer1]="footer1" [footer2]="footer2" [footer3]="footer3" [footer4]="footer4">
    </app-footer>

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition"
        (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>


</mat-sidenav-container>