import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFileUploadModule } from 'angular-material-fileupload';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};

import { PipesModule } from '../../pipes/pipes.module';
import { BrandsCarouselComponent } from './brands-carousel/brands-carousel.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { MainCarouselComponent } from './main-carousel/main-carousel.component';
import { ProductsCarouselComponent } from './products-carousel/products-carousel.component';
import { ProductsCarousel2Component } from './products-carousel-2/products-carousel-2.component';
import { DirectivesModule } from 'src/directives/directives.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CustomerPaymentComponent } from './payment/customer-payment.component';
import { CustomerShippingComponent } from './shipping/customer-shipping.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerPaymentsComponent } from './payment/customer-payments.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CustomerPayoutsComponent } from './payout/customer-payouts.component';
import { CustomerPayoutComponent } from './payout/customer-payout.component';
import { DisplayDialogComponent } from './display-dialog/display-dialog.component';
import { StorageServiceModule } from 'angular-webstorage-service';
import { DisplayVideoDialogComponent } from './display-dialog/display-video-dialog.component';
import { CardVerifyDialogComponent } from './payment/card-verify-dialog/card-verify-dialog.component';

import { CustomerPaypalComponent } from './paypal/customer-paypal.component';
import { NgxPayPalModule } from 'ngx-paypal';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SwiperModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    DirectivesModule,
    InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
    CreditCardDirectivesModule,
    MatFileUploadModule,
    StorageServiceModule,
    NgxPayPalModule
  ],
  exports: [
    RouterModule,
    SwiperModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    MainCarouselComponent,
    BrandsCarouselComponent,
    ProductsCarouselComponent,
    ProductsCarousel2Component,
    CategoryListComponent,
    DirectivesModule,
    InfiniteScrollModule,
    CustomerPaymentComponent,
    CustomerPaymentsComponent,
    CustomerShippingComponent,
    ConfirmationDialogComponent,
    CreditCardDirectivesModule,
    MatFileUploadModule,
    CustomerPayoutsComponent,
    CustomerPayoutComponent,
    DisplayDialogComponent,
    StorageServiceModule,
    CustomerPaypalComponent,
    NgxPayPalModule
  ],
  declarations: [
    MainCarouselComponent,
    BrandsCarouselComponent,
    ProductsCarouselComponent,
    ProductsCarousel2Component,
    CategoryListComponent,
    CustomerPaymentComponent,
    CustomerShippingComponent,
    CustomerPaymentsComponent,
    ConfirmationDialogComponent,
    CustomerPayoutsComponent,
    CustomerPayoutComponent,
    DisplayDialogComponent,
    DisplayVideoDialogComponent,
    CardVerifyDialogComponent,
    CustomerPaypalComponent
  ],
  providers: [
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    DecimalPipe
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    DisplayDialogComponent,
    DisplayVideoDialogComponent,
    CardVerifyDialogComponent
  ]
})
export class SharedModule { }
