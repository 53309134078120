<mat-toolbar class="top-navbar mat-elevation-z2 no-box content-center theme-container">
    <div *ngFor="let menuItem of headerMenus" style="height: 100%;">
        <div *ngIf="menuItem.menu_type=='list'" style="height: 100%;">
            <a style="height: 100%;padding-top:5px;font-family: 'Avenir Next';" mat-button   [matMenuTriggerFor]="subMenu" (click)="openMegaMenu()"
                #megaMenuTrigger="matMenuTrigger">
                {{menuItem.item_title}}
                <mat-icon class="caret"> arrow_drop_down </mat-icon>
            </a>
            <mat-menu #subMenu="matMenu" [overlapTrigger]="false" class="app-dropdown">
                <div (mouseleave)="megaMenuTrigger.closeMenu()">
                    <div *ngFor="let subMenuItem of menuItem.data">
                        <a mat-menu-item routerLink="{{'/products/category/'+subMenuItem.link}}"
                            routerLinkActive="horizontal-active-link"
                            [routerLinkActiveOptions]="{exact:true}">{{subMenuItem.item}}</a>
                    </div>
                    <!-- <div *ngFor="let subMenuItem of menuItem.data">
                        <a mat-menu-item
                            routerLink="{{'/products/search/'+subMenuItem.link+'/category/'+menuItem.link}}"
                            routerLinkActive="horizontal-active-link"
                            [routerLinkActiveOptions]="{exact:true}">{{subMenuItem.item}}</a>
                    </div> -->
                    <!-- <a mat-menu-item routerLink="{{'/products/category/'+menuItem.link}}"
                        ><i>Browse All
                            {{menuItem.item_title}}</i></a> -->
                </div>
            </mat-menu>
        </div>
        <div *ngIf="menuItem.menu_type == 'link'" style="height: 100%;">
            <a *ngIf="menuItem.link == ''" mat-button  style="height: 100%;padding-top:5px; font-family: 'Avenir Next';" 
                routerLink="{{menuItem.link}}" routerLinkActive="horizontal-active-link"
                [routerLinkActiveOptions]="{exact:true}">
                {{menuItem.item_title}}
            </a>
            <a *ngIf="menuItem.link != '' " mat-button  style="height: 100%;padding-top:5px; font-family: 'Avenir Next';" 
                routerLink="{{'/products/category/'+menuItem.link}}" routerLinkActive="horizontal-active-link"
                [routerLinkActiveOptions]="{exact:true}">
                {{menuItem.item_title}}
            </a>
        </div>
        <div class="dropdown" style="height: 100%;" *ngIf="menuItem.menu_type=='mega'"
            (mouseleave)="onMegaMenuData(menuItem?.data[0])">
            <button mat-button  style="height: 100%; font-family: 'Avenir Next';">
                {{menuItem.item_title }}<mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
            </button>
            <div class="dropdown-content">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-sm-2" style="padding-left: 0px;">
                        <table>
                           <ng-container *ngFor="let submenu of menuItem?.data">
                                <tr *ngIf="submenu && submenu.product_data && submenu.product_data.length > 0"
                                    [ngClass]="{'megaHover': submenu.item == megaMenuArray.item}"
                                    (mouseover)="onMegaMenuData(submenu)" style="text-align: center; ">
                                    <td style=" text-align: center;  border-style:none">
                                        {{submenu.item}}
                                    </td>

                                </tr>
                            </ng-container>

                        </table>
                    </div>
                    <div class="menu-vl col-md-10 col-lg-10 col-xl-10">
                        <div class="row" *ngIf="megaMenuArray">
                            <div class="col-md-12 titleMega">
                                <span class="megamenu-title-text">Trending {{megaMenuArray.item}}</span>
                            </div>
                            <div class="col-md-4" *ngFor="let megaMenu of megaMenuArray.product_data">
                                <mat-card class="product-item card-height mouseHoverProduct no-box" style="padding:0px">
                                   <mat-card-content>
                                        <div style="height: 300px;width: 250px;">
                                             <a class="image-link" [routerLink]="['/products/detail', megaMenu.seo]">
                                                <img [src]="megaMenu.img" class="swiper-lazy" alt="{{megaMenu.item}}"
                                                    onerror="this.src='assets/images/products/default_image.png'"
                                                    height="175px" width="auto" />
                                                <p class="title">{{megaMenu.brand}} </p>
                                                <p class="title" style="font-size: 12px;">{{megaMenu.item}}</p>
                                            </a>
                                     </div>
                                    </mat-card-content>
                            </mat-card>
                            </div>
                            <div class="col-md-12" style="padding:10px">
                                <button mat-raised-button class=" float-right buy-btn btn-effect"
                                    [routerLink]="['/products/category/'+megaMenuArray.link]"> Explore All
                                    {{megaMenuArray.item}}
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
     <div fxFlex="100%" fxLayoutAlign="end end" *ngIf="currencies && currencies.length > 0">
        <!-- <span *ngIf="isLoggedIn()===true">
            <a mat-button [routerLink]="['/wishlist']" routerLinkActive="router-link-active"  class="menuCSS" >
                <mat-icon class="mat-icon-sm caret" style="margin-right: 5px;">favorite_border</mat-icon>&nbsp;Wishlist
            </a>
        </span> -->
         <a  mat-button  style="font-family: 'Avenir Next';" [matMenuTriggerFor]="currencyMenu" class="menuCSS" *ngIf="!currencydisable"
            #currencyMenuTrigger="matMenuTrigger">
            {{currency}}<mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
        </a>
        <mat-menu #currencyMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
            <span (mouseleave)="currencyMenuTrigger.closeMenu()">
                <button mat-menu-item *ngFor="let cur of currencies" (click)="changeCurrency(cur)">
                    <span>{{cur}}</span>
                </button>
            </span>
        </mat-menu>
        <a class="menuCSS" mat-button  style="font-family: 'Avenir Next';" [matMenuTriggerFor]="loginMenu" #loginMenuTrigger="matMenuTrigger"
            *ngIf="isLoggedIn()===false">
            <!-- <mat-icon class="mat-icon-sm">settings</mat-icon> -->
            <span>My Account</span>
        </a>
        <mat-menu #loginMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account">
            <span (mouseleave)="accountMenuTrigger.closeMenu()" >
                <div fxLayout="row" fxLayoutAlign="left left" class="user-info text-center" (click)="navigateToLogin()">
                    <button mat-button class="login-btn">Login</button>
                </div>

                <a mat-menu-item routerLink="/account/buyer/activities" (click)="navigateToLogin()"
                    style="text-align: center;">
                    <span class="regtext">New to StockNSell?</span><span class="regtext text-red"> Start Here.</span>
                </a>
            </span>
        </mat-menu>
         <span *ngIf="isLoggedIn()===true">
            <a class="menuCSS" mat-button  style="font-family: 'Avenir Next';" [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger">
                <!-- <mat-icon class="mat-icon-sm account-icon">person</mat-icon>  -->
                <span fxShow="false" fxShow.gt-sm>My Account</span>
                <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
            </a>
            <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account">
                <span (mouseleave)="accountMenuTrigger.closeMenu()">
                    <div fxLayout="row" fxLayoutAlign="left left" class="user-info">
                        <p>Hello <a routerLink="/account/buyer/profile"
                                class="profile-link">{{getCustomerName() | titlecase}},
                            </a><br>
                            <small>({{getUserName()}})</small>
                        </p>
                    </div>
                    <div class="divider"></div>
                    <!-- <a mat-menu-item routerLink="/account/activities">
                        <mat-icon class="mat-icon-sm">trending_up</mat-icon>
                        <span>Account Activities</span>
                    </a> -->
                    <!-- <a mat-menu-item routerLink="/account/profile">
                        <mat-icon class="mat-icon-sm">person</mat-icon>
                        <span>Profile</span>
                    </a> -->
                    <a mat-menu-item routerLink="/account/buyer/activities">
                        <mat-icon class="mat-icon-sm">add_shopping_cart</mat-icon>
                        <span>Buyer</span>
                    </a>
                    <a mat-menu-item routerLink="/account/seller/activities">
                        <mat-icon class="mat-icon-sm">add_shopping_cart</mat-icon>
                        <span>Selling</span>
                    </a>

                    <!-- <a mat-menu-item routerLink="/account/orders">
                        <mat-icon class="mat-icon-sm">add_shopping_cart</mat-icon>
                        <span>Orders</span>
                    </a>
                    <a mat-menu-item routerLink="/account/rewards">
                        <mat-icon class="mat-icon-sm">card_giftcard</mat-icon>
                        <span>Rewards</span>
                    </a>
                    <a mat-menu-item routerLink="/account/payouts">
                        <mat-icon class="mat-icon-sm">money</mat-icon>
                        <span>Payouts</span>
                    </a> -->
                    <div class="divider"></div>
                    <a mat-menu-item routerLink="/contact">
                        <mat-icon class="mat-icon-sm">contacts</mat-icon>
                        <span>Contact Us</span>
                    </a>
                    <div class="divider"></div>
                    <a mat-menu-item routerLink="/sign-out">
                        <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                        <span>Sign Out</span>
                    </a>
                </span>
            </mat-menu>
        </span>
    </div>
</mat-toolbar>