<div *ngFor="let menu of parentMenu" class="menu-item">
    <a *ngIf="menu.routerLink && !menu.hasSubMenu" mat-button fxLayout="row" fxLayoutAlign="start center"
        [routerLink]="getNavigationURL(menu.routerLink)" routerLinkActive="active-link"
        [routerLinkActiveOptions]="{exact:true}" (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <span class="menu-title">{{menu.title}}</span>
    </a>
    <a *ngIf="menu.href && !menu.subMenu" mat-button fxLayout="row" fxLayoutAlign="start center"
        attr.href="{{'/products/category/'+menu.title}}" [attr.target]="menu.target || ''" (click)="onClick(menu.id)"
        [id]="'menu-item-'+menu.id">
        <span class="menu-title">{{menu.title}}</span>
    </a>
    <a *ngIf="menu.hasSubMenu" mat-button fxLayout="row" fxLayoutAlign="start center" (click)="onClick(menu.id)"
        [id]="'menu-item-'+menu.id">
        <span class="menu-title">{{menu.title}}</span>
        <mat-icon class="menu-expand-icon transition-2">arrow_drop_down</mat-icon>
    </a>

    <div *ngIf="menu.hasSubMenu" class="sub-menu" [id]="'sub-menu-'+menu.id">
        <app-sidenav-menu [menuItems]="menuItems" [menuParentId]="menu.id" isLoad=false></app-sidenav-menu>
    </div>
    
</div>
<div *ngIf="isLoad && currencies && currencies.length > 0">
    <div class="menu-item" >
    
        <a [routerLink]="['/wishlist']" routerLinkActive="router-link-active"  mat-button  fxLayoutAlign="start center">
            <span class="menu-title"> Wishlist</span>
      
    </a>
    </div>
    <div class="menu-item">
        <a mat-button (click)="currencyMenu=!currencyMenu;accountMenu=false;" *ngIf="!currencydisable" fxLayoutAlign="start center">
            <span>{{currency}}</span>
            <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
        </a>
        <span *ngIf="currencyMenu">
            <a *ngFor="let cur of currencies" mat-button fxLayoutAlign="start center" (click)="changeCurrency(cur)">
                <span class="menu-title">{{cur}}</span>
            </a>
        </span>
    </div>
    <div class="menu-item" *ngIf="isLoggedIn()===false">
        <a mat-menu-item (click)='navigateToLogin()'>
            <!-- <mat-icon class="mat-icon-sm">settings</mat-icon> -->
            <span>Log In</span>
        </a>
    </div>
    <div class="menu-item" *ngIf="isLoggedIn()===true">
        <a (click)="accountMenu=!accountMenu;currencyMenu=false" mat-button fxLayoutAlign="start center">
            <span>My Account</span>
            <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
        </a>
        <span *ngIf="accountMenu">
            <a mat-menu-item routerLink="/account/buyer" fxLayoutAlign="start center">
                <mat-icon class="mat-icon-sm">settings</mat-icon>
                <span>Buyer</span>
            </a>
            <a mat-menu-item routerLink="/account/seller" fxLayoutAlign="start center">
                <mat-icon class="mat-icon-sm">settings</mat-icon>
                <span>Seller</span>
            </a>
            <a mat-menu-item routerLink="/contact" fxLayoutAlign="start center">
                <mat-icon class="mat-icon-sm">contacts</mat-icon>
                <span>Contact Us</span>
            </a>
            <a mat-menu-item routerLink="/sign-out" fxLayoutAlign="start center">
                <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                <span>Sign Out</span>
            </a>
        </span>
    </div>
</div>