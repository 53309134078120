import {
    ActivatedRouteSnapshot,
    CanActivate,
    Route,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuardService implements CanActivate {


    constructor(private _authService: AuthService, private _router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this._authService.isAuthenticated()) {
            return true;
        }

        // navigate to login page
        this._router.navigate(['/sign-in'], { queryParams: { returnUrl: decodeURI(state.url) } });
        // you can save redirect url so after authing we can move them back to the page they requested
        return false;
    }

}
