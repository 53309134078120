/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./display-video-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i9 from "@angular/material/icon";
import * as i10 from "./display-video-dialog.component";
var styles_DisplayVideoDialogComponent = [i0.styles];
var RenderType_DisplayVideoDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DisplayVideoDialogComponent, data: {} });
export { RenderType_DisplayVideoDialogComponent as RenderType_DisplayVideoDialogComponent };
export function View_DisplayVideoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", " main-title"], ["style", " width:100%; height:100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""], ["style", "color: white;"]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(2, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "button", [["mat-icon-button", ""], ["style", "float: right;\n    padding-right: 12px;\n    color: white;\n       top: -4px;\n    "], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dialogRef.close(i1.ɵnov(_v, 6).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(6, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["cancel"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["style", "padding:15px; background:black;  height:80%; text-align: center"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["style", "width:100%; height:30px; color:white;text-align:center; display: inline; font-size: 14px; font-weight: 500"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["style", "padding-right: 12px;color: white;height: 30px;background: transparent;border: none;font-weight: 500;padding: 5px 15px;border-right: 2px solid;font-size: 16px; "], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).dialogRef.close(i1.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, null, [" Back to StockNSell "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "a", [["href", "https://stocknsell.com/cdn/"], ["style", "background: transparent;border: none;font-weight: 500;padding: 5px 10px;text-decoration: none;color: white;font-size: 16px;"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Learn More"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_5 = _co.data.data; _ck(_v, 6, 0, currVal_5); _ck(_v, 8, 0); var currVal_9 = _co.data.data; _ck(_v, 13, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 5).disabled || null); var currVal_3 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); var currVal_4 = i1.ɵnov(_v, 6).ariaLabel; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 8).inline; _ck(_v, 7, 0, currVal_6); var currVal_7 = _co.data.htmlContent; _ck(_v, 10, 0, currVal_7); var currVal_8 = i1.ɵnov(_v, 13).ariaLabel; _ck(_v, 12, 0, currVal_8); }); }
export function View_DisplayVideoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-display-dialog", [], null, null, null, View_DisplayVideoDialogComponent_0, RenderType_DisplayVideoDialogComponent)), i1.ɵdid(1, 49152, null, 0, i10.DisplayVideoDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var DisplayVideoDialogComponentNgFactory = i1.ɵccf("app-display-dialog", i10.DisplayVideoDialogComponent, View_DisplayVideoDialogComponent_Host_0, {}, {}, []);
export { DisplayVideoDialogComponentNgFactory as DisplayVideoDialogComponentNgFactory };
