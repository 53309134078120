import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterByIdPipe } from './filter-by-id.pipe';
import { FilterBrandsPipe } from './filter-brands.pipe';
import { BrandSearchPipe } from './brand-search.pipe';
import { OrderByPipe } from './order-by.pipe';
import { FxIfPipe } from './fx-if.pipe';
import { GroupByPipe } from './group-by.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FilterByIdPipe,
        FilterBrandsPipe,
        BrandSearchPipe,
        OrderByPipe,
        FxIfPipe,
        GroupByPipe,
        SafeHtmlPipe
    ],
    exports: [
        FilterByIdPipe,
        FilterBrandsPipe,
        BrandSearchPipe,
        OrderByPipe,
        FxIfPipe,
        GroupByPipe,
        SafeHtmlPipe
    ]
})
export class PipesModule { }
