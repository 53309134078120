import { Component } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { emailValidator } from 'src/utils/app-validators';
import { MatSnackBar } from '@angular/material';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'app-email-subscribe',
  templateUrl: './email-subscribe.component.html',
  styleUrls: ['./email-subscribe.component.scss']
})

export class EmailSubscribeComponent {

  error: string;
  mailForm: FormGroup;
  mailChimpEndpoint = 'https://stocknsell.us4.list-manage.com/subscribe/post-json?u=9a92fea875cdf0a6496cdd7f2&amp;id=76dfbd5454&';

  constructor(private http: HttpClient, public formBuilder: FormBuilder, public snackBar: MatSnackBar, ) { }

  ngOnInit() {
    this.mailForm = this.formBuilder.group({
      'email': ['', Validators.compose([Validators.required, emailValidator, Validators.maxLength(50)])],
    });
  }

  submitMailForm() {
    this.error = undefined;
    if (this.mailForm.valid) {
      const params = new HttpParams()
        .set('EMAIL', this.mailForm.controls.email.value)
        .set('b_9a92fea875cdf0a6496cdd7f2_76dfbd5454', ''); // hidden input name

      const mailChimpUrl = this.mailChimpEndpoint + params.toString();

      // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
      this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
        if (response.result && response.result !== 'error') {
          this.snackBar.open('Email subscribe successfully.', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        } else {
          this.error = response.msg;
          // this.snackBar.open(response.msg, '×',
          //   { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        }
      }, error => {
        this.snackBar.open('Please try after sometimes', '×',
          { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
        console.log(error);
      });
    }
  }
}
