import { FooterResponse } from 'src/models/dto/footer.response';
import { FooterMenuViewModel } from '../../models/viewmodel/footermenu.viewmodel';
import { BaseMapper } from '../base.mapper';
import { MenuItemMapper } from './menuitem.mapper';

export class FooterMenuMapper extends BaseMapper {
    public static mapToModel(response: FooterResponse): FooterMenuViewModel {
        const model: FooterMenuViewModel = new FooterMenuViewModel();
        this.autoMap(response, model);
        model.menu = MenuItemMapper.mapToModels(response.menu);
        return model;
    }

    public static mapToModels(responses: FooterResponse[]): FooterMenuViewModel[] {
        const models: FooterMenuViewModel[] = [];
        if (responses) {
            for (const response of responses) {
                const footerData = this.mapToModel(response);
                footerData.menu = MenuItemMapper.mapToModels(response.menu);
                models.push(footerData);
            }
        }
        return models;
    }
}
