import { AgmCoreModule } from '@agm/core';
import { ApiService } from 'src/services/api.service';
import { AppComponent } from './app.component';
import { AppInterceptor } from '../../utils/app-interceptor';
import { AppSettings, MetaTagConfiguration } from '../../services/app.settings';
import { AuthGuardService } from 'src/services/authguard.service';
import { AuthService } from 'src/services/auth.service';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CustomOverlayContainer } from '../../utils/custom-overlay-container';
import { FooterComponent } from './footer/footer.component';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { LocalStorageModule } from 'angular-2-local-storage';
import { LocalStoregesService } from 'src/services/localstorage.service';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material';
import { Md5 } from 'ts-md5/dist/md5';
import { MenuComponent } from './menu/menu.component';
import { menuScrollStrategy } from '../../utils/scroll-strategy';
import { NgModule, ErrorHandler } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotFoundComponent } from '../not-found/not-found.component';
import { Overlay, OverlayContainer } from '@angular/cdk/overlay';
import { PagesComponent } from './pages/pages.component';
import { routing } from './app.routing';
import { SharedModule } from '../shared/shared.module';
import { SidenavMenuComponent } from './sidenav-menu/sidenav-menu.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StorageServiceModule } from 'angular-webstorage-service';
import { TechErrorComponent } from '../tech-error/tech-error.component';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { EmbedVideo } from 'ngx-embed-video';
import { CustomErrorHandler } from 'src/services/error.handler';
import { NgxPayPalModule } from 'ngx-paypal';
import { BreadcrumbModule } from 'angular-crumbs';
import { EmailSubscribeComponent } from './email-subscribe/email-subscribe.component';
import { JsonpModule, Jsonp, Response } from '@angular/http';
import { SharedDataService } from 'src/services/shared-data.service';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domainName
  },
  palette: {
    popup: {
      background: '#efefef',
    },
    button: {
      background: '#689f38',
      text: 'white'
    }
  },
  position: 'bottom',
  theme: 'classic',
  layout: 'my-custom-layout',
  layouts: {
    'my-custom-layout': '{{messagelink}}{{compliance}}'
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">
    <span>StockNSell.com uses cookies to improve user experience. By using our website you consent to our cookies in accordance with our <a href="/pages/privacy-policy" target="_blank">Cookie Policy</a>.</span>
    </span>
    `,
  },
  content: {
    message: '',

    // cookiePolicyLink: 'Cookie Policy',
    // cookiePolicyHref: 'https://cookie.com',

    // privacyPolicyLink: 'Privacy Policy',
    // privacyPolicyHref: '/pages/privacy-policy',

    // tosLink: 'Terms of Service',
    // tosHref: '/terms',
    dismiss: 'Close',
    deny: 'Refuse cookies',
    allow: 'Allow cookies'
  }
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    StorageServiceModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBNcjxo_35qnEG17dQvvftWa68eZWepYE0'
    }),
    SharedModule,
    routing,
    LocalStorageModule.forRoot({
      prefix: 'stock-n-sell-app',
      storageType: 'localStorage'
    }),
    FormsModule,
    ReactiveFormsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    EmbedVideo.forRoot(),
    NgxPayPalModule,
    BreadcrumbModule,
    JsonpModule,
    HttpClientJsonpModule
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    TechErrorComponent,
    MenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    FooterComponent,
    EmailSubscribeComponent
  ],
  providers: [
    AppSettings,
    MetaTagConfiguration,
    ApiService,
    LocalStoregesService,
    AuthGuardService,
    AuthService,
    SharedDataService,
    CookieService, {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    Md5,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay] },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
