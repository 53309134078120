import { Component, Input, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { DialogDataViewModel } from 'src/models/viewmodel/dialog-data.viewmodel';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomerPaymentViewModel } from 'src/models/viewmodel/customer-payment/customer-payment.viewmodel';
import { ApiService } from 'src/services/api.service';
import { VerifyCardRequest } from 'src/models/dto/customer-payment/verify-card.request';
import { ApiResultResponse } from 'src/models/dto/apiresult.response';


@Component({
  selector: 'app-card-verify-dialog',
  templateUrl: './card-verify-dialog.component.html',
  styleUrls: ['./card-verify-dialog.component.scss']
})
export class CardVerifyDialogComponent {
  payment: CustomerPaymentViewModel;
  verifyCardForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CardVerifyDialogComponent>, public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataViewModel, private apiService: ApiService, public snackBar: MatSnackBar) {
    this.payment = data.data;
    this.verifyCardForm = this.formBuilder.group({
      'amount1': ['', Validators.compose([Validators.required,
      Validators.pattern(/^0\.[0-9][0-9]$/)])],
      'amount2': ['', Validators.compose([Validators.required,
      Validators.pattern(/^0\.[0-9][0-9]$/)])],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick() {
    if (this.verifyCardForm.valid) {
      const request: VerifyCardRequest = {
        cc_id: this.payment.payment_id,
        amount1: this.verifyCardForm.controls.amount1.value,
        amount2: this.verifyCardForm.controls.amount2.value
      };

      this.apiService.verificationCard(request)
        .subscribe((data: ApiResultResponse) => {
          this.dialogRef.close(data);
        });
    }
  }
}

