var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpService } from './http.service';
import { EventEmitter } from '@angular/core';
var ApiService = /** @class */ (function (_super) {
    __extends(ApiService, _super);
    function ApiService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currencyNotification = new EventEmitter();
        _this.disableCurrency = new EventEmitter();
        return _this;
    }
    ApiService.prototype.getHeaderData = function () {
        return this.get('Menu');
    };
    ApiService.prototype.SendNotificationForCurrencyChange = function () {
        this.currencyNotification.emit();
    };
    ApiService.prototype.disabledCurrency = function (x) {
        this.disableCurrency.emit(x);
    };
    // public getHomeData(): Observable<HomeResponse> {
    //     return this.get<HomeResponse>('Home');
    // }
    ApiService.prototype.getHomeData = function (productPerLine) {
        console.log(productPerLine);
        return this.get('Home?perrow=' + productPerLine.number);
    };
    ApiService.prototype.getProductBySeo = function (seo, isInternational) {
        return this.get('Product/' + seo + '?is_international=' + (isInternational ? '1' : '0'));
    };
    ApiService.prototype.getViewedProductListByIds = function (productIds) {
        return this.get('Product/getListByIds?product_ids=' + productIds);
    };
    ApiService.prototype.getProductAllBids = function (productId, bidType, isInternational) {
        return this.get('Bid/viewAllBids/' + productId + '/' + bidType + '/' + (isInternational ? '1' : '0'));
    };
    ApiService.prototype.login = function (login) {
        return this.post('Customer/validate', login);
    };
    ApiService.prototype.getProductBidData = function (bidData, loader) {
        if (loader === void 0) { loader = false; }
        return this.postWithToken('bid/bidPageData', bidData, loader);
    };
    ApiService.prototype.getProductLiveSearchDetails = function (request) {
        return this.get('Product/livesearch?search=' + request.search + '&category=' + request.category);
    };
    ApiService.prototype.getProductSearchByKeyWord = function (request, loading) {
        if (loading === void 0) { loading = false; }
        var specification = null;
        if (request.color) {
            specification = { 'color': request.color };
        }
        if (request.size) {
            if (specification) {
                specification = { 'color': request.color, 'size': request.size };
            }
            else {
                specification = { 'size': request.size };
            }
        }
        return this.get('Product/search?search=' + request.search + '&category=' + request.category
            + '&brand=' + request.brand
            + '&specification=' + (specification ? JSON.stringify(specification) : '')
            + '&page=' + request.totalRecords + ',' + request.count
            + '&min_highest_bid=' + request.min_highest_bid
            + '&max_highest_bid=' + request.max_highest_bid
            + '&min_lowest_ask=' + request.min_lowest_ask
            + '&max_lowest_ask=' + request.max_lowest_ask
            + '&sort_by=' + request.sortBy
            + '&sort_type=' + request.sortType, loading);
    };
    ApiService.prototype.getCustomer = function () {
        return this.getWithToken('Customer/get');
    };
    ApiService.prototype.saveCustomer = function (customer) {
        return this.post('Customer/save', customer);
    };
    ApiService.prototype.saveProductBid = function (bid) {
        return this.postWithToken('Bid/save', bid);
    };
    ApiService.prototype.getCustomerDefaultAddress = function () {
        return this.getWithToken('Customer/getDefaultAddress');
    };
    ApiService.prototype.saveCustomerAddress = function (customerAddress) {
        return this.postWithToken('Customer/saveAddress', customerAddress);
    };
    ApiService.prototype.getCountries = function () {
        return this.get('Customer/countries');
    };
    ApiService.prototype.getZones = function (countryCode) {
        return this.get('Customer/zones/' + countryCode);
    };
    ApiService.prototype.saveProductOrderNow = function (productOrderNow) {
        return this.postWithToken('Order/createOrderTxn', productOrderNow);
    };
    ApiService.prototype.getDiscountCodeInfo = function (couponCode) {
        return this.getWithToken('Customer/getDiscountCodeInfo/' + couponCode);
    };
    ApiService.prototype.getCustomerAvailability = function (request) {
        return this.get('Customer/isAvailable?type=' + request.type + '&value=' + request.value);
    };
    ApiService.prototype.setDefaultCurrency = function (currency) {
        return this.getWithToken('Customer/setDefaultCurrency/' + currency);
    };
    ApiService.prototype.resetPassword = function (request) {
        return this.post('Customer/resetPasswordLink', request);
    };
    ApiService.prototype.profileResetPassword = function (request) {
        return this.postWithToken('Customer/resetPassword', request);
    };
    ApiService.prototype.socialLogin = function (request) {
        return this.post('Customer/socialLogin', request);
    };
    ApiService.prototype.getOrderById = function (request) {
        return this.getWithToken('Order/getOrder/' + request.type + '/' + request.id);
    };
    ApiService.prototype.getOrderHistory = function () {
        return this.getWithToken('Order/getOrderHistory');
    };
    ApiService.prototype.getBidByCustomer = function () {
        return this.getWithToken('Bid/getBidByCustomer');
    };
    ApiService.prototype.getProductAskData = function (bidData, loading) {
        if (loading === void 0) { loading = false; }
        return this.postWithToken('bid/askPageData', bidData, loading);
    };
    ApiService.prototype.sendResetPasswordLink = function (email) {
        return this.get('Customer/sendResetPasswordLink?email=' + email);
    };
    ApiService.prototype.sendVerificationEmail = function (email) {
        return this.get('Customer/sendVerificationEmail?email=' + email);
    };
    ApiService.prototype.toggleVacationMode = function () {
        return this.getWithToken('Customer/toggleVacationMode');
    };
    ApiService.prototype.logout = function () {
        return this.getWithToken('Customer/logout');
    };
    ApiService.prototype.createCustomerPayment = function (payment) {
        return this.postWithToken('Customer/addPaymentMethod', payment);
    };
    ApiService.prototype.updateCustomerPayment = function (payment) {
        return this.postWithToken('Customer/savePaymentMethod', payment);
    };
    ApiService.prototype.deleteCustomerPayment = function (paymentId) {
        return this.getWithToken('Customer/deletePaymentMethod?payment_id=' + paymentId);
    };
    ApiService.prototype.getCustomerPayments = function () {
        return this.getWithToken('Customer/listPaymentMethod');
    };
    ApiService.prototype.getCustomerPaymentById = function (paymentId) {
        return this.getWithToken('Customer/getPaymentMethod?payment_id=' + paymentId);
    };
    ApiService.prototype.setDefaultPaymentMethod = function (paymentId) {
        return this.getWithToken('Customer/setDefaultPaymentMethod?payment_id=' + paymentId);
    };
    ApiService.prototype.getAllDiscountCode = function () {
        return this.getWithToken('Customer/getAllDiscountCode');
    };
    ApiService.prototype.createCustomerProductRequest = function (product) {
        return this.postWithToken('Product/requestProduct', product);
    };
    ApiService.prototype.createCustomerPayoutRequest = function (payout) {
        return this.postWithToken('Customer/newPayoutMethod', payout);
    };
    ApiService.prototype.getCustomerPayoutMethods = function () {
        return this.getWithToken('Customer/getPayoutMethods');
    };
    ApiService.prototype.deleteCustomerPayoutMethods = function (id) {
        return this.getWithToken('Customer/deletePayoutMethods/' + id);
    };
    ApiService.prototype.saveCustomerOrderTracking = function (trackingDetails) {
        return this.postWithToken('Order/saveTracking', trackingDetails);
    };
    ApiService.prototype.deleteCustomerBidDetails = function (id) {
        return this.getWithToken('Bid/delete/' + id);
    };
    ApiService.prototype.uploadDocument = function (file) {
        return this.uploadFile('upload/document/id', file);
    };
    ApiService.prototype.uploadBankDocument = function (file) {
        return this.uploadFile('upload/document/payout', file);
    };
    ApiService.prototype.saveAccountType = function (accountDetails) {
        return this.postWithToken('Customer/saveAccountType', accountDetails);
    };
    ApiService.prototype.setDefaultPayoutMethod = function (payoutId) {
        return this.getWithToken('Customer/setDefaultPayout/' + payoutId);
    };
    // https://stocknsell.com/dev/services/Customer/verifyEmail?email=arzoo.shah@icloud.com&code=BMhMnxe9JtmSTFobgEm9k3n9lMrQOU
    ApiService.prototype.verifyEmail = function (email, code) {
        return this.get('Customer/verifyEmail?email=' + email + '&code=' + code);
    };
    ApiService.prototype.getDynamicPage = function (seo) {
        return this.get('Pages/' + seo);
    };
    ApiService.prototype.getBidAskAvailability = function () {
        return this.getWithToken('bid/available');
    };
    ApiService.prototype.getSellerDashboardData = function () {
        return this.getWithToken('Customer/sellerDashboard');
    };
    ApiService.prototype.saveContactDetails = function (request) {
        return this.post('Contact/submit', request);
    };
    ApiService.prototype.getContactMaster = function () {
        return this.get('Contact');
    };
    ApiService.prototype.getFAQs = function () {
        return this.get('FAQ');
    };
    ApiService.prototype.markAsShipped = function (request) {
        return this.postWithToken('Order/markAsShipped', request);
    };
    ApiService.prototype.prepareShipping = function (request, orderId, packageQty) {
        return this.postWithToken("shipping/prepareShipping/" + orderId + "/" + packageQty, request);
    };
    ApiService.prototype.getDimensions = function (orderId) {
        return this.getWithToken("shipping/getDim/" + orderId);
    };
    ApiService.prototype.getNotifications = function () {
        return this.getWithToken('Notification');
    };
    ApiService.prototype.saveNotification = function (request) {
        return this.postWithToken('Notification/set', request);
    };
    ApiService.prototype.logError = function (request) {
        return this.post('Home/addError', request, true);
    };
    ApiService.prototype.startVerification = function (request) {
        return this.postWithToken('PS/startVerification', request);
    };
    ApiService.prototype.verificationCard = function (request) {
        return this.postWithToken('PS/verifyCC', request);
    };
    ApiService.prototype.getWishlist = function () {
        return this.getWithToken('customer/getWishlist');
    };
    ApiService.prototype.addWishlist = function (product_id) {
        return this.postWithToken('customer/addWishlist/' + product_id, {});
    };
    ApiService.prototype.removeWishlist = function (product_id) {
        return this.deleteWithToken('customer/removeWishlist/' + product_id);
    };
    return ApiService;
}(HttpService));
export { ApiService };
