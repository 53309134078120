import { BaseMapper } from '../base.mapper';
import { CategoryResponse } from '../../models/dto/home.response';
import { CategoryViewModel } from '../../models/viewmodel/home.viewmodel';
import { SubMenuItemResponse } from '../../models/dto/menuitem.response';
import { SubMenuItemViewModel } from 'src/models/viewmodel/menuitem.viewmodel';

export class CategoryMapper extends BaseMapper {
    public static mapToModel(response: CategoryResponse): CategoryViewModel {
        const model: CategoryViewModel = new CategoryViewModel();
        this.autoMap(response, model);
        //PENDING
        model.parentId=0;
        return model;
    }

    public static mapToModels(responses: CategoryResponse[]): CategoryViewModel[] {
        const models: CategoryViewModel[] = [];
        if (responses) {
            for (const response of responses) {
                models.push(this.mapToModel(response));
            }
        }
        return models;
    }
}
