import { SubMenuItemViewModel } from 'src/models/viewmodel/menuitem.viewmodel';
import { SubMenuItemResponse } from '../../models/dto/menuitem.response';
import { BaseMapper } from '../base.mapper';
import { MegaMenuMapper } from './megamenu.mapper';

export class SubHeaderMenuMapper extends BaseMapper {
    public static mapToModel(response: SubMenuItemResponse): SubMenuItemViewModel {
        const model: SubMenuItemViewModel = new SubMenuItemViewModel();
        this.autoMap(response, model);
     
        model.product_data = MegaMenuMapper.mapToModels(response.product_data);
        
        return model;
    }

    public static mapToModels(responses: SubMenuItemResponse[]): SubMenuItemViewModel[] {
        const models: SubMenuItemViewModel[] = [];
        if (responses) {
            for (const response of responses) {
                
                const megaData = this.mapToModel(response);
                megaData.product_data = MegaMenuMapper.mapToModels(response.product_data);
                models.push(megaData);
            }
        }
        return models;
    }
}
