import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
 
 
  constructor() { }

  carousalMobileView:any

}
 