import { AppSettings, Settings } from '../../../services/app.settings';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  startWith,
  switchMap,
  takeWhile,
  tap
} from 'rxjs/operators';
import { CategoryMapper } from 'src/mapper/menu/category.mapper';
import { CategoryViewModel } from '../../../models/viewmodel/home.viewmodel';
import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  Inject
} from '@angular/core';
import { CurrencyConversionMenuMapper } from 'src/mapper/menu/currencyconversion.mapper';
import { CurrencyConversionViewModel } from 'src/models/viewmodel/currencyconversion.viewmodel';
import { CurrencyDetailViewModel } from 'src/models/viewmodel/currencydetail.viewmodel';
import { FooterMenuMapper } from 'src/mapper/menu/footermenu.mapper';
import { FooterMenuViewModel } from 'src/models/viewmodel/footermenu.viewmodel';
import { FormControl } from '@angular/forms';
import { HeaderMenuMapper } from 'src/mapper/menu/headermenu.mapper';
import { HeaderMenuViewModel } from 'src/models/viewmodel/headermenu.viewmodel';
import { LocalStoregesService } from 'src/services/localstorage.service';
import { MainMenuResponse } from 'src/models/dto/mainmenu.response';
import { NavigationEnd, Router, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductLiveSearchInfoMapper } from 'src/mapper/product/productlivesearch.mapper';
import { ProductLiveSearchInfoViewModel } from 'src/models/viewmodel/product/productlivesearch.viewmodel';
import { ProductLiveSearchRequest } from 'src/models/dto/product/productlivesearch.request';
import { ProductLiveSearchResponse } from 'src/models/dto/product/productlivesearch.response';
import { SidenavMenuService } from '../sidenav-menu/sidenav-menu.service';
import { SidenavMenuViewModel } from '../../../models/viewmodel/sidenavmenu.viewmodel';
import { ApiService } from 'src/services/api.service';
import { Title } from '@angular/platform-browser';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { delay } from 'rxjs-compat/operator/delay';
import { StickyHeaderViewModel } from 'src/models/viewmodel/sticky-header.viewmodel';
import { StickyHeaderMapper } from '../../../mapper/menu/sticky-header.mapper';
import { MatDialog } from '@angular/material';
import { DisplayVideoDialogComponent } from '../../shared/display-dialog/display-video-dialog.component';
import { EmbedVideoService } from 'ngx-embed-video';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [SidenavMenuService]
})
export class PagesComponent implements OnInit {
  public showBackToTop = false;
  public categories: CategoryViewModel[];
  public category: CategoryViewModel;
  public sidenavMenuItems: SidenavMenuViewModel[];
  @ViewChild('sidenav') sidenav: any;
  private _apiService: ApiService;
  public settings: Settings;
  public headerMenus: HeaderMenuViewModel[] = [];
  public footer: FooterMenuViewModel;
  public footer1: FooterMenuViewModel;
  public footer2: FooterMenuViewModel;
  public footer3: FooterMenuViewModel;
  public footer4: FooterMenuViewModel;
  public currency: string[];
  public conversion: CurrencyConversionViewModel;
  public stickyHeader: StickyHeaderViewModel;
  public searchControl = new FormControl();
  public filteredOptions: Observable<any[]>;
  mobileSearchInput = false;
  public searchCategory: ProductLiveSearchInfoViewModel;
  breadcrumbs = [];
  constructor(private breadcrumbService: BreadcrumbService, public appSettings: AppSettings, private embedService: EmbedVideoService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    public sidenavMenuService: SidenavMenuService,
    private _localStorage: LocalStoregesService,
    public apiService: ApiService,
    public router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private _localStoregeService: LocalStoregesService, public dialog: MatDialog, ) {
    this.settings = this.appSettings.settings;
    this._apiService = apiService;
    this.router.events.subscribe((event: any) => {
      this.titleService.setTitle('StockNSell');
      this.getTitle(this.activatedRoute);
      if ((!(this.storage.get('auth_token') ? true : false)) && event && event.urlAfterRedirects) {
        if (event.urlAfterRedirects.indexOf('/search') > -1 ||
          event.urlAfterRedirects.indexOf('/category') > -1 ||
          (event.urlAfterRedirects.indexOf('/products') > -1 &&
            event.urlAfterRedirects.indexOf('/products') + 8 === event.urlAfterRedirects.length - 1)
        ) {

        } else {
          this.searchControl.setValue(undefined);
          if (this.category && this.categories && this.categories.length > 0) {
            this.category = this.categories[0];
          }
        }
      }
    });
  }


  private getTitle(route: ActivatedRoute): any[] {
    const ROUTE_DATA_BREADCRUMB = 'title';
    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return undefined;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getTitle(child);
      }

      // add breadcrumb
      const title = child.snapshot.data[ROUTE_DATA_BREADCRUMB];

      // this.titleService.setTitle('StockNSell - ' + title);
    }
  }





  ngOnInit() {


    this.filteredOptions = this.searchControl.valueChanges
      .pipe(
        startWith(null),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(val => {
          return this.filter(val || '');
        })
      );

    this._localStorage.watchStorage().subscribe((data: string) => {
      if (this._localStorage.getLiveSearchFilterStatus() === true) {
        this.searchControl.setValue(undefined);
        this._localStorage.setLiveSearchFilterStatus(false);
      }
    });

    this._apiService.getHeaderData()
      .subscribe((mainMenu: MainMenuResponse) => {
        this.headerMenus = HeaderMenuMapper.mapToModels(mainMenu.main);
        this.footer = FooterMenuMapper.mapToModel(mainMenu.footer);
        this.footer1 = FooterMenuMapper.mapToModel(mainMenu.footer1);
        this.footer2 = FooterMenuMapper.mapToModel(mainMenu.footer2);
        this.footer3 = FooterMenuMapper.mapToModel(mainMenu.footer3);
        this.footer4 = FooterMenuMapper.mapToModel(mainMenu.footer4);
        this.categories = CategoryMapper.mapToModels(mainMenu.category);
        this.stickyHeader = StickyHeaderMapper.mapToModel(mainMenu.sticky_header);
        this.category = this.categories[0];
        const defaultCurrency = mainMenu.default_currency;
        this.currency = mainMenu.currency;
        this.conversion = CurrencyConversionMenuMapper.mapToModel(mainMenu.conversion);
        const localCurrencyDetail = this._localStoregeService.getCurrencyDetail();
        const currencyDetail: CurrencyDetailViewModel = {
          currency: defaultCurrency ? defaultCurrency :
            localCurrencyDetail && localCurrencyDetail.currency ? localCurrencyDetail.currency : this.currency[0],
          usd_to_cad: this.conversion.usd_to_cad,
          cad_to_usd: this.conversion.cad_to_usd,
          displayCurrency: undefined,
          usd_to_cad_std: this.conversion.usd_to_cad_std,
          cad_to_usd_std: this.conversion.cad_to_usd_std,
        };
        this._localStoregeService.setCurrencyDetail(currencyDetail);
        this.setSidenavMenuItems();
      }
        , (error) => {
          // pending show error
        });




  }



  mobileSearchInputShowMethod(mobileSearchInput) {
    console.log(mobileSearchInput);

    this.mobileSearchInput = !mobileSearchInput;
    this.searchControl.setValue(undefined);
  }

  openVideoDialog() {
    const dialogRef = this.dialog.open(DisplayVideoDialogComponent, {
      panelClass: 'list-container',
      width: '80%',
      height: '80%',
      data: {
        title: 'How Its works',
        message: '',
        btnOkText: 'Ok',
        data: true,
        htmlContent: this.embedService.embed('https://www.youtube.com/watch?v=_RwnWNAg9-o', {
          attr: { width: '95%', height: '100%' }
        })
      }
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  private setSidenavMenuItems() {
    this.sidenavMenuItems = [];
    let i = 1;
    this.headerMenus.forEach(element => {
      const sideNavMenu: SidenavMenuViewModel = {
        id: i++,
        title: element.item_title,
        parentId: 0,
        routerLink: element.link,
        href: undefined,
        target: undefined,
        hasSubMenu: false,
      };

      if (element.menu_type === 'list') {
        sideNavMenu.hasSubMenu = true;
        sideNavMenu.routerLink = null;
        element.data.forEach(subElement => {
          const subSideNavMenu: SidenavMenuViewModel = {
            id: i++,
            title: subElement.item,
            parentId: sideNavMenu.id,
            routerLink: subElement.link,
            href: undefined,
            target: undefined,
            hasSubMenu: false,
          };
          this.sidenavMenuItems.push(subSideNavMenu);
        });
      }
      this.sidenavMenuItems.push(sideNavMenu);
    });
  }
  public changeCategory(event) {
    if (event.target) {
      this.category = this.categories.filter(category => category.category === event.target.innerText)[0];
      this.searchControl.setValue(undefined);
    }
    if (window.innerWidth < 960) {
      this.stopClickPropagate(event);
    }
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {

    event.stopPropagation();
    event.preventDefault();
  }

  public search() {
    this.router.navigate(['/products/detail', this.searchCategory.seo]);
  }


  public scrollToTop() {
    const scrollDuration = 200;
    const scrollStep = -window.pageYOffset / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.pageYOffset !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => { window.scrollTo(0, 0); });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    if (this.sidenavMenuItems && this.sidenavMenuItems.length > 0) {
      this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuItems);
    }
  }
  isLoggedIn() {
    return this.storage.get('auth_token') ? true : false;
  }
  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus(this.sidenavMenuItems);
    }
  }

  public changeCurrency(data) {
    const currencyDetail: CurrencyDetailViewModel = {
      currency: data,
      usd_to_cad: this.conversion.usd_to_cad,
      cad_to_usd: this.conversion.cad_to_usd,
      displayCurrency: undefined,
      usd_to_cad_std: this.conversion.usd_to_cad_std,
      cad_to_usd_std: this.conversion.cad_to_usd_std,
    };
    this._localStoregeService.setCurrencyDetail(currencyDetail);
    this._apiService.setDefaultCurrency(data);
  }

  filter(val: string): Observable<ProductLiveSearchInfoViewModel[]> {
    const searchRequest: ProductLiveSearchRequest = {
      search: val,
      category: this.category && this.category.category !== 'All Categories' ? this.category.category : ''
    };

    return this._apiService.getProductLiveSearchDetails(searchRequest)
      .pipe(
        map((response: any) =>
          response
        )
      );
  }
  navigateToProduct(category: ProductLiveSearchInfoViewModel) {
    if (category) {
      this.searchCategory = category;
      this.router.navigate(['/products/detail', category.seo]);
      this.mobileSearchInputShowMethod(true);
    } else {
      this.router.navigate(['/products']);
      this.mobileSearchInputShowMethod(true);
    }
  }

  navigateToAllProduct(searchValue: string, category: CategoryViewModel) {
    if (searchValue) {
      category.category !== 'All Categories' ?
        this.router.navigateByUrl('/products/search/' + searchValue.replace(/\//g, '^*') +
          '/category/' + category.category) :
        this.router.navigateByUrl('/products/search/' + searchValue.replace(/\//g, '^*'));
      this.mobileSearchInputShowMethod(true);
    } else {
      this.router.navigate(['/products']);
      this.mobileSearchInputShowMethod(true);
    }
  }

}
