import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Inject
} from '@angular/core';
import { LocalStoregesService } from 'src/services/localstorage.service';
import { SidenavMenuService } from './sidenav-menu.service';
import { WebStorageService, LOCAL_STORAGE } from 'angular-webstorage-service';
import { Router } from '@angular/router';
import { ApiService } from 'src/services/api.service';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  providers: [SidenavMenuService]
})
export class SidenavMenuComponent implements OnInit {
  @Input('menuItems')
  menuItems;
  @Input('menuParentId')
  menuParentId;
  parentMenu: Array<any>;

  @Input()
  currencies: string[] = [];

  @Input()
  isLoad = false;

  @Output() onChangeCurrency: EventEmitter<any> = new EventEmitter();

  public currency: string;
  public currencyMenu = false;
  public accountMenu = false;
  currencydisable = false
  constructor(private sidenavMenuService: SidenavMenuService, private _localStoregeService: LocalStoregesService, 
    private router: Router,    @Inject(LOCAL_STORAGE) private storage: WebStorageService,private api_service:ApiService) { }

  ngOnInit() {
    this.parentMenu = this.menuItems.filter(item => item.parentId === this.menuParentId);
    if (this.currencies && this.currencies.length > 0) {
      const localCurrencyDetail = this._localStoregeService.getCurrencyDetail();
      this.currency = localCurrencyDetail && localCurrencyDetail.currency ? localCurrencyDetail.currency : this.currencies[0];
    }


    this.api_service.disableCurrency.subscribe((data)=>{
      this.currencydisable = data
    })
  }

  isLoggedIn() {
    return this.storage.get('auth_token') ? true : false;
  }

  onClick(menuId) {
    this.sidenavMenuService.toggleMenuItem(menuId);
    this.sidenavMenuService.closeOtherSubMenus(this.menuItems, menuId);
  }

  public changeCurrency(currency) {
    this.currency = currency;
    this.onChangeCurrency.emit(currency);
    this.currencyMenu = false;
  }

  getNavigationURL(routerLink: string) {
    const routerLinks = routerLink.split('/');
    if (routerLinks && routerLinks.length > 1) {
      return '/products/search/' + routerLinks[1] + '/category/' + routerLinks[0];
    }
    return '/products/category/' + routerLinks[0];
  }

  navigateToLogin() {
    if (this.router.url.includes('sign-in', 0)) {
      window.location.reload();
    } else {
      this.router.navigateByUrl('/sign-in');
    }
  }

}
