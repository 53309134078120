import { Injectable, Inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { MatSnackBar } from '@angular/material';
import { Router, RouterStateSnapshot } from '@angular/router';
import { WebStorageService, LOCAL_STORAGE } from 'angular-webstorage-service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService, private snackBar: MatSnackBar, public router: Router,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const loader = req.params.get('loader');

    if (!loader || loader !== 'true') {
      this.spinner.show();
    }

    req.params.delete('loader', loader);

    return next.handle(req).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.spinner.hide();
      }
    }, (err: any) => {
      this.spinner.hide();
      if (err instanceof HttpErrorResponse) {
        const started = Date.now();

        if (err.status === 404) {
          this.router.navigate(['/404']);
        } else if (err.status === 401) {
          this.snackBar.open('Session is expired. Please login.', '×',
            { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
          if (this.storage.get('auth_token') ? true : false) {
            this.storage.remove('auth_token');
            this.storage.remove('customer_id');
            this.storage.remove('customer_name');
            this.storage.remove('customer_username');
          }
          this.router.navigate(['/sign-in'], { queryParams: { returnUrl: this.router.url } });
        } else if (err.status === 500) {
          this.router.navigate(['/tech-error']);
        } else {
          this.handleError();
        }
      }
    });
  }

  private handleError() {
    this.snackBar.open('Please try after some time', '×',
      { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
  }
}
