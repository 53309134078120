<div class=" main-title" style=" width:100%; height:100%;">
  <h1 mat-dialog-title style="color: white;">
    {{data.title}}
    <button mat-icon-button style="float: right;
    padding-right: 12px;
    color: white;
       top: -4px;
    " [mat-dialog-close]="data.data">
      <mat-icon>cancel</mat-icon>
    </button>
  </h1>

<div [innerHTML]='data.htmlContent' style="padding:15px; background:black;  height:80%; text-align: center">
</div>
<div style="width:100%; height:30px; color:white;text-align:center; display: inline; font-size: 14px; font-weight: 500">
  <button style="padding-right: 12px;color: white;height: 30px;background: transparent;border: none;font-weight: 500;padding: 5px 15px;border-right: 2px solid;font-size: 16px; " [mat-dialog-close]="data.data">
    Back to StockNSell
  </button> <a href="https://stocknsell.com/cdn/" target="_blank" style="background: transparent;border: none;font-weight: 500;padding: 5px 10px;text-decoration: none;color: white;font-size: 16px;">Learn More</a>
</div>
</div>
<!-- <div mat-dialog-actions style="float: right;margin-top: 10px;">
  <button mat-raised-button color="primary" [mat-dialog-close]="data.data" cdkFocusInitial>{{ data.btnOkText }}</button>
</div> -->