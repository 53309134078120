import { BaseMapper } from '../base.mapper';
import { StickyHeaderViewModel } from 'src/models/viewmodel/sticky-header.viewmodel';
import { StickyHeaderResponse } from 'src/models/dto/mainmenu.response';

export class StickyHeaderMapper extends BaseMapper {
    public static mapToModel(response: StickyHeaderResponse): StickyHeaderViewModel {
        const model: StickyHeaderViewModel = new StickyHeaderViewModel();
        this.autoMap(response, model);
        return model;
    }

    public static mapToModels(responses: StickyHeaderResponse[]): StickyHeaderViewModel[] {
        const models: StickyHeaderViewModel[] = [];
        if (responses) {
            for (const response of responses) {
                models.push(this.mapToModel(response));
            }
        }
        return models;
    }
}
