import { FormControl, FormGroup, AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';

export function emailValidator(control: FormControl): { [key: string]: any } {
    const emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return { invalidEmail: true };
    }
}

export function phoneNumberValidator(control: FormControl): { [key: string]: any } {
    const phoneRegexp = /([+0-9.-]{10,30})$/;
    const value = control.value ? control.value.trim() : control.value;
    if (value && !phoneRegexp.test(value)) {
        return { invalidPhoneNumber: true };
    } else if (value && value.split('+').length > 2) {
        return { invalidPhoneNumber: true };
    }
    // else if (control.value && control.value.indexOf('00000') > -1) {
    //     return { invalidPhoneNumber: true };
    // }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        const password = group.controls[passwordKey];
        const passwordConfirmation = group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({ mismatchedPasswords: true });
        } else {
            return passwordConfirmation.setErrors({ mismatchedPasswords: false });
        }
    };
}

export function priceValidator(control: FormControl): { [key: string]: any } {
    const priceRegexp = /(\d)/g;
    if (control.value && !priceRegexp.test(control.value)) {
        return { invalidPrice: true };
    } else if (control.value && (parseFloat(control.value) > 99999999999.99)) {
        return { invalidPrice: true };
    }
}

export function qtyValidator(control: FormControl): { [key: string]: any } {
    if (control.value && isNaN(control.value)) {
        return { invalidQty: true };
    }
}

export function postalCACodeValidator(control: FormControl): { [key: string]: any } {
    const canadaPostalRegexp = /^[a-zA-Z]\d{1}[a-zA-Z](\-| |)\d{1}[a-zA-Z]\d{1}$/;
    if (control.value && !canadaPostalRegexp.test(control.value)) {
        return { invalidPostalCode: true };
    }
}

export function postalUSCodeValidator(control: FormControl): { [key: string]: any } {
    const usPostalRegexp = /^[0-9]{5}([- /]?[0-9]{4})?$/;
    if (control.value && !usPostalRegexp.test(control.value)) {
        return { invalidPostalCode: true };
    }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && (control.dirty || control.touched));
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
        if (invalidCtrl || (control.invalid && form.submitted) ||
            (invalidParent && control.parent.errors && control.parent.errors.hasOwnProperty('notPasswordSame'))) {
            return true;
        }
    }
}

