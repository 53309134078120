export class StickyHeaderViewModel {
    background: string;
    color: string;
    font_size: string;
    font_style: string;
    margin: string;
    padding: string;
    show: number;
    style: any;
    text: string;
    text_align: string;
    weight: string;
}
