import { CountriesResponse } from 'src/models/dto/profile/country.response';
import { CustomerAddressRequest } from 'src/models/dto/profile/customer-address.request';
import { CustomerAddressResponse } from 'src/models/dto/profile/customer-address.response';
import { CustomerAvailabilityRequest, CustomerAvailabilityResponse } from 'src/models/dto/login/customeravalibility.request';
import { CustomerBidAskDetailsResponse } from 'src/models/dto/customer-bid-ask/customerbidaskdetails.response';
import { CustomerRequest } from 'src/models/dto/profile/customer.request';
import { CustomerResponse } from 'src/models/dto/profile/customer.response';
import { Observable } from 'rxjs';
import { HomeResponse, ProductDescriptionResponse } from '../models/dto/home.response';
import { HttpService } from './http.service';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { LoginRequest } from 'src/models/dto/login/login.request';
import { LoginResponse } from 'src/models/dto/login/login.response';
import { MainMenuResponse } from 'src/models/dto/mainmenu.response';
import { OrderApiResponse } from 'src/models/dto/customerorder/order.response';
import { OrderHistoryApiResponse } from 'src/models/dto/customerorder/orderhistory.response';
import { OrderRequest } from 'src/models/dto/customerorder/order.request';
import { ProductBidInfoResponse, ProductBidResponse } from 'src/models/dto/product/productbidinfo.response';
import { ProductBidRequest } from 'src/models/dto/product/productbid.request';
import { ProductBidSaveRequest } from 'src/models/dto/product/productbidsave.request';
import { ProductBidSaveResponse } from 'src/models/dto/product/productbidsave.response';
import { ProductDetailResponse } from 'src/models/dto/product/productdetail.response';
import { ProductDiscountCodeResponse } from 'src/models/dto/product/productdiscountcode.response';
import { ProductLiveSearchResponse } from 'src/models/dto/product/productlivesearch.response';
import { ProductLiveSearchRequest } from 'src/models/dto/product/productlivesearch.request';
import { ProductOrderNowSaveRequest } from 'src/models/dto/product/productordernowsave.request';
import { ProductSearchByKeyWordRequest } from 'src/models/dto/product/productsearchbykeyword.request';
import { ProductSearchByKeyWordResponse } from 'src/models/dto/product/productsearchbykeyword.response';
import { ProfileResetPasswordRequest } from 'src/models/dto/profile/profileresetpassword.request';
import { ResetPasswordRequest } from 'src/models/dto/login/resetpasword.request';
import { SocialLoginRequest } from 'src/models/dto/login/sociallogin.request';
import { ZonesResponse } from 'src/models/dto/profile/zone.response';
import { ProductAskRequest } from 'src/models/dto/product/productask.request';
import { ProductAskInfoResponse } from 'src/models/dto/product/productask.response';
import { CustomerPaymentRequest } from 'src/models/dto/customer-payment/customer-payment.request';
import { ApiResultResponse } from '../models/dto/apiresult.response';
import { CustomerDiscountDataResponse } from 'src/models/dto/customer-payment/customer-discount.response';
import { CustomerProductCreateRequest } from 'src/models/dto/product/customer-product.request';
import { CustomerPaymentDataResponse } from 'src/models/dto/customer-payment/customer-payment.response';
import { CreateCustomerPayoutRequest } from '../models/dto/customer-payout/customer-create-payout.request';
import { CustomerPayoutResponse } from 'src/models/dto/customer-payout/customer-payout.response';
import { CustomerOrderTrackingRequest } from 'src/models/dto/customerorder/order-tracking.request';
import { CustomerAccountTypeRequest } from 'src/models/dto/profile/customer-account-type.request';
import { DynamicPageApiResponse } from '../models/dto/dynamic-page/dynamic-page.response';
import { CustomerBidAskAvailabilityApiResponse } from '../models/dto/customer-bid-ask/customer-bid-ask-availability.response';
import { DashboardApiResponse } from 'src/models/dto/dashboard/seller-dashboard.response';
import * as $ from 'jquery';
import { DocumentUploadApiResponse } from 'src/models/dto/document-upload/document-upload.response';
import { ContactRequest } from '../models/dto/contact/contact.request';
import { ContactApiResponse } from 'src/models/dto/contact/contact.response';
import { FAQResponse } from '../models/dto/faq/faq.response';
import { OrderShippedStatusRequest } from '../models/dto/customerorder/order-status-mark.request';
import { CustomerOrderPrintLabelRequest } from 'src/models/dto/customerorder/order-print-label.request';
import { OrderPrintLabelApiResponse } from '../models/dto/customerorder/order-print-label.response';
import { NotificationsApiResponse } from 'src/models/dto/notifications/notifications.response';
import { NotificationRequest } from 'src/models/dto/notifications/notification.request';
import { CustomErrorRequest } from 'src/models/dto/custom-error.request';
import { InitiateVerifyCardRequest, VerifyCardRequest } from 'src/models/dto/customer-payment/verify-card.request';
import { ProductPerLineRequest } from 'src/models/dto/product-per-line.request';
import { WishlistProductsResponse } from 'src/models/dto/wishlist-products.response';
@Injectable()
export class ApiService extends HttpService {
    @Output() currencyNotification  = new EventEmitter()
    @Output() disableCurrency  = new EventEmitter()
    public getHeaderData(): Observable<MainMenuResponse> {
        return this.get<MainMenuResponse>('Menu');
    }

    SendNotificationForCurrencyChange(){
     this.currencyNotification.emit()
    }


    disabledCurrency(x){
this.disableCurrency.emit(x)
    }

    // public getHomeData(): Observable<HomeResponse> {
    //     return this.get<HomeResponse>('Home');
    // }
    public getHomeData(productPerLine:ProductPerLineRequest): Observable<HomeResponse> {
        console.log(productPerLine);
        
        return this.get<HomeResponse>('Home?perrow='+productPerLine.number);
    }
    
    public getProductBySeo(seo: string, isInternational: boolean): Observable<ProductDetailResponse> {
        return this.get<ProductDetailResponse>('Product/' + seo + '?is_international=' + (isInternational ? '1' : '0'));
    }

    public getViewedProductListByIds(productIds: string): Observable<ProductDescriptionResponse[]> {
        return this.get<ProductDescriptionResponse[]>('Product/getListByIds?product_ids=' + productIds);
    }

    public getProductAllBids(productId: number, bidType: string, isInternational: boolean): Observable<ProductBidResponse[]> {
        return this.get<ProductBidResponse[]>('Bid/viewAllBids/' + productId + '/' + bidType + '/' + (isInternational ? '1' : '0'));
    }

    public login(login: LoginRequest): Observable<LoginResponse> {
        return this.post<LoginRequest, LoginResponse>('Customer/validate', login);
    }

    public getProductBidData(bidData: ProductBidRequest, loader = false): Observable<ProductBidInfoResponse> {
        return this.postWithToken<ProductBidRequest, ProductBidInfoResponse>('bid/bidPageData', bidData, loader);
    }

    public getProductLiveSearchDetails(request: ProductLiveSearchRequest): Observable<ProductLiveSearchResponse> {
        return this.get<ProductLiveSearchResponse>
            ('Product/livesearch?search=' + request.search + '&category=' + request.category);
    }

    public getProductSearchByKeyWord(request: ProductSearchByKeyWordRequest, loading = false): Observable<ProductSearchByKeyWordResponse> {
        let specification = null;
        if (request.color) {
            specification = { 'color': request.color };
        }
        if (request.size) {
            if (specification) {
                specification = { 'color': request.color, 'size': request.size };
            } else {
                specification = { 'size': request.size };
            }
        }
        return this.get<ProductSearchByKeyWordResponse>
            ('Product/search?search=' + request.search + '&category=' + request.category
                + '&brand=' + request.brand
                + '&specification=' + (specification ? JSON.stringify(specification) : '')
                + '&page=' + request.totalRecords + ',' + request.count
                + '&min_highest_bid=' + request.min_highest_bid
                + '&max_highest_bid=' + request.max_highest_bid
                + '&min_lowest_ask=' + request.min_lowest_ask
                + '&max_lowest_ask=' + request.max_lowest_ask
                + '&sort_by=' + request.sortBy
                + '&sort_type=' + request.sortType, loading);
    }

    public getCustomer(): Observable<CustomerResponse[]> {
        return this.getWithToken<CustomerResponse[]>('Customer/get');
    }

    public saveCustomer(customer: CustomerRequest): Observable<any> {
        return this.post<CustomerRequest, ApiResultResponse>('Customer/save', customer);
    }

    public saveProductBid(bid: ProductBidSaveRequest): Observable<ProductBidSaveResponse> {
        return this.postWithToken<ProductBidSaveRequest, ProductBidSaveResponse>('Bid/save', bid);
    }

    public getCustomerDefaultAddress(): Observable<CustomerAddressResponse[]> {
        return this.getWithToken<CustomerAddressResponse[]>('Customer/getDefaultAddress');
    }

    public saveCustomerAddress(customerAddress: CustomerAddressRequest): Observable<any> {
        return this.postWithToken<CustomerAddressRequest, ApiResultResponse>('Customer/saveAddress', customerAddress);
    }

    public getCountries(): Observable<CountriesResponse> {
        return this.get<CountriesResponse>('Customer/countries');
    }

    public getZones(countryCode: string): Observable<ZonesResponse> {
        return this.get<ZonesResponse>('Customer/zones/' + countryCode);
    }

    public saveProductOrderNow(productOrderNow: ProductOrderNowSaveRequest): Observable<any> {
        return this.postWithToken<ProductOrderNowSaveRequest, ApiResultResponse>('Order/createOrderTxn', productOrderNow);
    }

    public getDiscountCodeInfo(couponCode: string): Observable<ProductDiscountCodeResponse> {
        return this.getWithToken<ProductDiscountCodeResponse>('Customer/getDiscountCodeInfo/' + couponCode);
    }

    public getCustomerAvailability(request: CustomerAvailabilityRequest): Observable<CustomerAvailabilityResponse> {
        return this.get<CustomerAvailabilityResponse>('Customer/isAvailable?type=' + request.type + '&value=' + request.value);
    }

    public setDefaultCurrency(currency: string): Observable<ApiResultResponse> {
        return this.getWithToken<ApiResultResponse>('Customer/setDefaultCurrency/' + currency);
    }

    public resetPassword(request: ResetPasswordRequest): Observable<ApiResultResponse> {
        return this.post<ResetPasswordRequest, ApiResultResponse>('Customer/resetPasswordLink', request);
    }

    public profileResetPassword(request: ProfileResetPasswordRequest): Observable<ApiResultResponse> {
        return this.postWithToken<ProfileResetPasswordRequest, ApiResultResponse>('Customer/resetPassword', request);
    }

    public socialLogin(request: SocialLoginRequest): Observable<LoginResponse> {
        return this.post<SocialLoginRequest, LoginResponse>('Customer/socialLogin', request);
    }

    public getOrderById(request: OrderRequest): Observable<OrderApiResponse> {
        return this.getWithToken<OrderApiResponse>('Order/getOrder/' + request.type + '/' + request.id);
    }

    public getOrderHistory(): Observable<OrderHistoryApiResponse> {
        return this.getWithToken<OrderHistoryApiResponse>('Order/getOrderHistory');
    }

    public getBidByCustomer(): Observable<CustomerBidAskDetailsResponse[]> {
        return this.getWithToken<CustomerBidAskDetailsResponse[]>('Bid/getBidByCustomer');
    }

    public getProductAskData(bidData: ProductAskRequest, loading: boolean = false): Observable<ProductAskInfoResponse> {
        return this.postWithToken<ProductAskRequest, ProductAskInfoResponse>('bid/askPageData', bidData, loading);
    }

    public sendResetPasswordLink(email: string) {
        return this.get<ApiResultResponse>('Customer/sendResetPasswordLink?email=' + email);
    }

    public sendVerificationEmail(email: string) {
        return this.get<ApiResultResponse>('Customer/sendVerificationEmail?email=' + email);
    }

    public toggleVacationMode() {
        return this.getWithToken<ApiResultResponse>('Customer/toggleVacationMode');
    }

    public logout() {
        return this.getWithToken<ApiResultResponse>('Customer/logout');
    }

    public createCustomerPayment(payment: CustomerPaymentRequest): Observable<ApiResultResponse> {
        return this.postWithToken<CustomerPaymentRequest, ApiResultResponse>('Customer/addPaymentMethod', payment);
    }

    public updateCustomerPayment(payment: CustomerPaymentRequest): Observable<ApiResultResponse> {
        return this.postWithToken<CustomerPaymentRequest, ApiResultResponse>('Customer/savePaymentMethod', payment);
    }

    public deleteCustomerPayment(paymentId: number): Observable<ApiResultResponse> {
        return this.getWithToken('Customer/deletePaymentMethod?payment_id=' + paymentId);
    }

    public getCustomerPayments(): Observable<CustomerPaymentDataResponse> {
        return this.getWithToken('Customer/listPaymentMethod');
    }

    public getCustomerPaymentById(paymentId: number): Observable<CustomerPaymentDataResponse> {
        return this.getWithToken('Customer/getPaymentMethod?payment_id=' + paymentId);
    }

    public setDefaultPaymentMethod(paymentId: number): Observable<ApiResultResponse> {
        return this.getWithToken('Customer/setDefaultPaymentMethod?payment_id=' + paymentId);
    }

    public getAllDiscountCode(): Observable<CustomerDiscountDataResponse> {
        return this.getWithToken('Customer/getAllDiscountCode');
    }

    public createCustomerProductRequest(product: CustomerProductCreateRequest): Observable<ApiResultResponse> {
        return this.postWithToken<CustomerProductCreateRequest, ApiResultResponse>('Product/requestProduct', product);
    }

    public createCustomerPayoutRequest(payout: CreateCustomerPayoutRequest): Observable<ApiResultResponse> {
        return this.postWithToken<CreateCustomerPayoutRequest, ApiResultResponse>('Customer/newPayoutMethod', payout);
    }

    public getCustomerPayoutMethods(): Observable<CustomerPayoutResponse> {
        return this.getWithToken<CustomerPayoutResponse>('Customer/getPayoutMethods');
    }

    public deleteCustomerPayoutMethods(id: number): Observable<ApiResultResponse> {
        return this.getWithToken<ApiResultResponse>('Customer/deletePayoutMethods/' + id);
    }

    public saveCustomerOrderTracking(trackingDetails: CustomerOrderTrackingRequest): Observable<ApiResultResponse> {
        return this.postWithToken<CustomerOrderTrackingRequest, ApiResultResponse>('Order/saveTracking', trackingDetails);
    }

    public deleteCustomerBidDetails(id: number): Observable<ApiResultResponse> {
        return this.getWithToken<ApiResultResponse>('Bid/delete/' + id);
    }

    public uploadDocument(file: File): Observable<DocumentUploadApiResponse> {
        return this.uploadFile<File, DocumentUploadApiResponse>('upload/document/id', file);
    }

    public uploadBankDocument(file: File): Observable<DocumentUploadApiResponse> {
        return this.uploadFile<File, DocumentUploadApiResponse>('upload/document/payout', file);
    }

    public saveAccountType(accountDetails: CustomerAccountTypeRequest): Observable<ApiResultResponse> {
        return this.postWithToken<CustomerAccountTypeRequest, ApiResultResponse>('Customer/saveAccountType', accountDetails);
    }

    public setDefaultPayoutMethod(payoutId: number): Observable<ApiResultResponse> {
        return this.getWithToken('Customer/setDefaultPayout/' + payoutId);
    }

    // https://stocknsell.com/dev/services/Customer/verifyEmail?email=arzoo.shah@icloud.com&code=BMhMnxe9JtmSTFobgEm9k3n9lMrQOU
    public verifyEmail(email: string, code: string) {
        return this.get<ApiResultResponse>('Customer/verifyEmail?email=' + email + '&code=' + code);
    }

    public getDynamicPage(seo: string): Observable<DynamicPageApiResponse> {
        return this.get('Pages/' + seo);
    }

    public getBidAskAvailability(): Observable<CustomerBidAskAvailabilityApiResponse> {
        return this.getWithToken('bid/available');
    }

    public getSellerDashboardData(): Observable<DashboardApiResponse> {
        return this.getWithToken('Customer/sellerDashboard');
    }

    public saveContactDetails(request: ContactRequest) {
        return this.post<ContactRequest, ApiResultResponse>('Contact/submit', request);
    }

    public getContactMaster() {
        return this.get<ContactApiResponse>('Contact');
    }

    public getFAQs() {
        return this.get<FAQResponse[]>('FAQ');
    }

    public markAsShipped(request: OrderShippedStatusRequest): Observable<ApiResultResponse> {
        return this.postWithToken<OrderShippedStatusRequest, ApiResultResponse>('Order/markAsShipped', request);
    }

    public prepareShipping(request: CustomerOrderPrintLabelRequest, orderId, packageQty): Observable<ApiResultResponse> {
        return this.postWithToken<CustomerOrderPrintLabelRequest, ApiResultResponse>
            (`shipping/prepareShipping/${orderId}/${packageQty}`, request);
    }

    public getDimensions(orderId): Observable<OrderPrintLabelApiResponse> {
        return this.getWithToken(`shipping/getDim/${orderId}`);
    }

    public getNotifications(): Observable<NotificationsApiResponse> {
        return this.getWithToken('Notification');
    }

    public saveNotification(request: NotificationRequest): Observable<ApiResultResponse> {
        return this.postWithToken<NotificationRequest, ApiResultResponse>('Notification/set', request);
    }

    public logError(request: CustomErrorRequest): Observable<ApiResultResponse> {
        return this.post<CustomErrorRequest, ApiResultResponse>('Home/addError', request, true);
    }

    public startVerification(request: InitiateVerifyCardRequest): Observable<ApiResultResponse> {
        return this.postWithToken<InitiateVerifyCardRequest, ApiResultResponse>('PS/startVerification', request);
    }

    public verificationCard(request: VerifyCardRequest): Observable<ApiResultResponse> {
        return this.postWithToken<VerifyCardRequest, ApiResultResponse>('PS/verifyCC', request);
    }



    public getWishlist():Observable<WishlistProductsResponse>{
        return this.getWithToken('customer/getWishlist')   
       }
       public addWishlist(product_id:number):Observable<WishlistProductsResponse>{
           return this.postWithToken('customer/addWishlist/'+product_id,{})    
       }
       public removeWishlist(product_id):Observable<WishlistProductsResponse>{
           return this.deleteWithToken('customer/removeWishlist/'+product_id)    
       }
}
