var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseMapper } from '../base.mapper';
import { MenuItemMapper } from './menuitem.mapper';
var HeaderMenuMapper = /** @class */ (function (_super) {
    __extends(HeaderMenuMapper, _super);
    function HeaderMenuMapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HeaderMenuMapper.mapToModel = function (response) {
        return MenuItemMapper.mapToModel(response);
    };
    HeaderMenuMapper.mapToModels = function (responses) {
        var models = [];
        if (responses) {
            return MenuItemMapper.mapToModels(responses);
        }
        return models;
    };
    return HeaderMenuMapper;
}(BaseMapper));
export { HeaderMenuMapper };
