<div class="fill-container">
    <mat-card fxLayout="row wrap" fxLayoutAlign="space-around" class="bg-skyblue  no-box">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3" style="padding-top: 30px;">
            <div fxLayout="column" fxLayoutAlign="center center" class="text-center">
                <div class="header-logo text-center" [ngStyle.xs]="{'width':'100%','text-align':'left'}">

                    <img src="assets/images/logo/header_logo2.png" alt="StockNSell Logo">

                </div>
                <div style="text-align:justify;">
                    <span> when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        It has survived not only five centuries, but also the leap into electronic typesetting,
                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                        sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum. </span>

                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2" style="padding-top: 30px;"
            [ngStyle.xs]="{'padding-top':'15px'}">
            <div fxLayout="column" fxLayoutAlign.gt-xs="center center">


                <div fxShow="false" fxShow.xl fxShow.lg fxShow.md fxShow.sm>
                    <h2 class="dark-text py-1">About Us </h2>
                    <mat-list>
                        <mat-list-item> Delivery Information </mat-list-item>
                        <mat-list-item> Order History </mat-list-item>
                        <mat-list-item> Privacy Policy </mat-list-item>
                        <mat-list-item> Help </mat-list-item>
                    </mat-list>
                </div>








                <mat-accordion style="box-shadow: none;background: #f8fcff;" fxShow="false" fxShow.xs>
                    <mat-expansion-panel style="box-shadow: none;background: #f8fcff;color:#010344">
                        <mat-expansion-panel-header style="box-shadow: none;background: #f8fcff;color:#010344;padding: 0px ;
                      font-size: 1.5em;
                      
                      font-weight: bold;">

                            About Us


                        </mat-expansion-panel-header>

                        <mat-list>
                            <mat-list-item> Delivery Information </mat-list-item>
                            <mat-list-item> Order History </mat-list-item>
                            <mat-list-item> Privacy Policy </mat-list-item>
                            <mat-list-item> Help </mat-list-item>
                        </mat-list>
                    </mat-expansion-panel>

                </mat-accordion>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2" style="padding-top: 30px;"
            [ngStyle.xs]="{'padding-top':'15px'}">
            <div fxLayout="column" fxLayoutAlign.gt-xs="center center">
                <div fxShow="false" fxShow.xl fxShow.lg fxShow.md fxShow.sm>
                    <h2 class="dark-text py-1">Information</h2>
                    <mat-list>
                        <mat-list-item> My Account </mat-list-item>
                        <mat-list-item> Return Policy </mat-list-item>
                        <mat-list-item> Your Orders </mat-list-item>
                    </mat-list>
                </div>


                <mat-accordion style="box-shadow: none;background: #f8fcff;" fxShow="false" fxShow.xs>
                    <mat-expansion-panel style="box-shadow: none;background: #f8fcff;color:#010344">
                        <mat-expansion-panel-header style="box-shadow: none;background: #f8fcff;color:#010344 ;padding: 0px ;
                  font-size: 1.5em;
                  
                  font-weight: bold;">

                            Information


                        </mat-expansion-panel-header>

                        <mat-list>
                            <mat-list-item> My Account </mat-list-item>
                            <mat-list-item> Return Policy </mat-list-item>
                            <mat-list-item> Your Orders </mat-list-item>
                        </mat-list>
                    </mat-expansion-panel>

                </mat-accordion>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2" style="padding-top: 30px;"
            [ngStyle.xs]="{'padding-top':'15px'}">
            <div fxLayout="column" fxLayoutAlign.gt-xs="center center">
                <div fxShow="false" fxShow.xl fxShow.lg fxShow.md fxShow.sm>
                    <h2 class="dark-text py-1">My Account</h2>
                    <mat-list>
                        <mat-list-item> My Account </mat-list-item>
                        <mat-list-item> Checkout </mat-list-item>
                        <mat-list-item> Order Status </mat-list-item>
                        <mat-list-item> Sell Your Product</mat-list-item>
                    </mat-list>
                </div>



                <mat-accordion style="box-shadow: none;background: #f8fcff;" fxShow="false" fxShow.xs>
                    <mat-expansion-panel style="box-shadow: none;background: #f8fcff;color:#010344">
                        <mat-expansion-panel-header style="box-shadow: none;background: #f8fcff;color:#010344 ;padding: 0px ;
                  font-size: 1.5em;
                  
                  font-weight: bold;">

                            My Account


                        </mat-expansion-panel-header>
                        <mat-list>
                            <mat-list-item> My Account </mat-list-item>
                            <mat-list-item> Checkout </mat-list-item>
                            <mat-list-item> Order Status </mat-list-item>
                            <mat-list-item> Sell Your Product</mat-list-item>
                        </mat-list>
                    </mat-expansion-panel>

                </mat-accordion>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3" style="padding-top: 30px;">
            <div fxLayout="column" fxLayoutAlign.gt-xs="center center">
                <h2 class="dark-text py-1">Sign Up To Newsletter</h2>
                <div>
                    <span> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                        passages, of Lorem Ipsum. </span>

                </div>

                <app-email-subscribe></app-email-subscribe>

                <!-- <div class="example-container"  fxLayout="column">
                <div fxFlex="100" fxLayoutAlign="center center">
                    <mat-form-field appearance="fill">
                        <mat-label>Enter Your Email</mat-label>
                        <input matInput type="email">
                
                    </mat-form-field>
                </div>
                    
                    <div fxFlex="100" fxLayoutAlign="center center">
                        <button  mat-raised-button   style="height: 50px;background-color: rgba(0, 0, 0, 0.79);color: white;">Subscribe</button>
                    </div>
            </div> -->
            </div>
        </div>

    </mat-card>

    <mat-card fxLayout="row wrap" fxLayoutAlign="space-around" class="  no-box">

        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">

        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
            <p style="text-align: center;">2020 Business. All Rights Reserved</p>

        </div>
        <!-- <div  class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
      
        <p style="    text-align: center;" >Privacy Policy</p>
    </div>
  

        <div  
        class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
        <p style="    text-align: center;" >Terms and Conditions</p>
    </div>
  
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">

    </div> -->
    </mat-card>





    <mat-card fxLayout="row wrap" fxLayoutAlign="space-around" class="bg-skyblue  no-box">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div fxLayout="column" fxLayoutAlign="center center" class="text-center">

                <div style="text-align:justify;" [ngStyle.xs]="{'margin':'0px 0px 40px 0px'}">
                    <span class="productRequest" [routerLink]="['/products/request-product']">Can't find the product you
                        are looking for ? <a> Request for it now <mat-icon style=" font-size: 32px;
                height: 30px;
                width: 28px;vertical-align: middle;
               ">arrow_forward</mat-icon></a> </span>

                </div>
            </div>
        </div>


    </mat-card>
</div>


<!-- 

<div class="fill-container bg-skyblue">
    <mat-card fxLayout="row wrap" fxLayoutAlign="space-around" class="bg-skyblue theme-container no-box">
        <div fxFlex="100"  fxFlex.gt-sm="20" ngClass.xs="mt-2" style="padding-top: 30px;">
            <div fxLayout="column" fxLayoutAlign="center center" class="text-center">
                <div class="header-logo text-center">
                
                        <img src="assets/images/logo/header_logo2.png" alt="StockNSell Logo">
                    
                </div>
                <div style="text-align:justify;">
                    <span>  when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </span>
        
                </div>
            </div>
        </div>
        <div fxFlex="100"  fxFlex.gt-sm="20"  style="padding-top: 30px;">
            <div fxLayout="column"  fxLayoutAlign.gt-xs="center center">
                <h2 class="dark-text py-1">About Us </h2>
                <mat-list>
                    <mat-list-item> Delivery Information </mat-list-item>
                    <mat-list-item> Order History </mat-list-item>
                    <mat-list-item> Privacy Policy </mat-list-item>
                    <mat-list-item> Help </mat-list-item>
                </mat-list>
            </div>
        </div>
        <div fxFlex="100"  fxFlex.gt-sm="20"  style="padding-top: 30px;">
            <div fxLayout="column"  fxLayoutAlign.gt-xs="center center">
                <h2 class="dark-text py-1">Information</h2>
                <mat-list>
                    <mat-list-item> My Account </mat-list-item>
                    <mat-list-item> Return Policy </mat-list-item>
                    <mat-list-item> Your Orders </mat-list-item>
                </mat-list>
            </div>
        </div>
        <div fxFlex="100"  fxFlex.gt-sm="20"  style="padding-top: 30px;">
            <div fxLayout="column"  fxLayoutAlign.gt-xs="center center">
                <h2 class="dark-text py-1">My Account</h2>
                <mat-list>
                    <mat-list-item> My Account </mat-list-item>
                    <mat-list-item> Checkout </mat-list-item>
                    <mat-list-item> Order Status </mat-list-item>
                    <mat-list-item> Sell Your Product</mat-list-item>
                </mat-list>
            </div>
        </div>
        <div fxFlex="100"  fxFlex.gt-sm="20"  style="padding-top: 30px;">
            <div fxLayout="column"  fxLayoutAlign.gt-xs="center center">
                <h2 class="dark-text py-1">Sign Up To Newsletter</h2>
                <div>
                    <span>  It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,  of Lorem Ipsum. </span>
        
                </div>
           

            <div class="example-container"  fxLayout="column">
                <div fxFlex="100" fxLayoutAlign="center center">
                    <mat-form-field appearance="fill">
                        <mat-label>Enter Your Email</mat-label>
                        <input matInput type="email">
                
                    </mat-form-field>
                </div>
                    
                    <div fxFlex="100" fxLayoutAlign="center center">
                        <button  mat-raised-button   style="height: 50px;background-color: rgba(0, 0, 0, 0.79);color: white;">Subscribe</button>
                    </div>
            </div>
            </div>
        </div>
    
    </mat-card>
</div> -->