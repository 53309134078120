import { Component, Input, OnInit } from '@angular/core';
import { FooterMenuViewModel } from '../../../models/viewmodel/footermenu.viewmodel';
import { Location } from '@angular/common';
import {  Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat = 40.678178;
  public lng = -73.944158;
  public zoom = 12;
  basePath: string;
  @Input()
  footer1: FooterMenuViewModel;
  @Input()
  footer2: FooterMenuViewModel;
  @Input()
  footer3: FooterMenuViewModel;
  @Input()
  footer4: FooterMenuViewModel;
  @Input()
  footer: FooterMenuViewModel;
  constructor(public location: Location,private router:Router) {
    const loc = <any>this.location.valueOf();
    this.basePath = loc._platformStrategy._platformLocation.location.origin;
  }

  ngOnInit() {
    console.log(this.router.url );
    
   }

  subscribe() { }
  openMegaMenu() {
    const pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
      if (el.children.length > 0) {
        if (el.children[0].classList.contains('mega-menu')) {
          el.classList.add('mega-menu-pane');
        }
      }
    });
  }

}
