import { MenuItemResponse } from 'src/models/dto/menuitem.response';
import { MenuItemViewModel } from 'src/models/viewmodel/menuitem.viewmodel';
import { BaseMapper } from '../base.mapper';
import { SubHeaderMenuMapper } from './subheadermenu.mapper';

export class MenuItemMapper extends BaseMapper {
    public static mapToModel(response: MenuItemResponse): MenuItemViewModel {
        const model: MenuItemViewModel = new MenuItemViewModel();
        this.autoMap(response, model);
        model.data = SubHeaderMenuMapper.mapToModels(response.data);
        return model;
    }

    public static mapToModels(responses: MenuItemResponse[]): MenuItemViewModel[] {
        const models: MenuItemViewModel[] = [];
        if (responses) {
            for (const response of responses) { 

                const headerData = this.mapToModel(response);
                headerData.data = SubHeaderMenuMapper.mapToModels(response.data);
                models.push(headerData);
            }
        }
        return models;
    }
}
