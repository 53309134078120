import { Pipe, PipeTransform } from '@angular/core';
import { ObservableMedia, MediaChange } from '@angular/flex-layout';
import { Observable } from 'rxjs';
import 'rxjs/Rx';

@Pipe({ name: 'fxIf' })
export class FxIfPipe implements PipeTransform {

    constructor(private media: ObservableMedia) { }

    transform(data: Array<'xs' | 'sm' | 'md' | 'lg' | 'xl'>): Observable<boolean> {
        return this.media.asObservable().map((change: MediaChange) => {
            return data.indexOf(<any>change.mqAlias) > -1;
        });
    }
}
