/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i5 from "ngx-spinner";
import * as i6 from "./app.component";
import * as i7 from "ngx-cookieconsent";
import * as i8 from "../../services/app.settings";
import * as i9 from "@angular/platform-browser";
import * as i10 from "ngx-cookie-service/cookie-service/cookie.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "h-100 app"], ["id", "app"]], [[2, "cookie-overlay", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", "\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "ngx-spinner", [["bdColor", "rgba(51,51,51,0.7)"], ["class", "loader"], ["color", "#fff"], ["fullScreen", "true"], ["size", "large"], ["style", "color: #f54d40 !important"], ["type", "ball-atom"]], null, null, null, i4.View_NgxSpinnerComponent_0, i4.RenderType_NgxSpinnerComponent)), i1.ɵdid(7, 770048, null, 0, i5.NgxSpinnerComponent, [i5.NgxSpinnerService], { bdColor: [0, "bdColor"], size: [1, "size"], color: [2, "color"], type: [3, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "h-100 app"; var currVal_2 = _co.settings.theme; _ck(_v, 1, 0, currVal_1, currVal_2); _ck(_v, 3, 0); var currVal_4 = "rgba(51,51,51,0.7)"; var currVal_5 = "large"; var currVal_6 = "#fff"; var currVal_7 = "ball-atom"; _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.cookieStatus; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.setCookiesTerm(); _ck(_v, 5, 0, currVal_3); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4440064, null, 0, i6.AppComponent, [i7.NgcCookieConsentService, i8.AppSettings, i9.Title, i3.Router, i10.CookieService, i3.ActivatedRoute, i2.Location, i8.MetaTagConfiguration], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
