
import { BaseMapper } from '../base.mapper';
import { MegamenuResponse } from 'src/models/dto/menuitem.response';
import { MegamenuViewModel } from 'src/models/viewmodel/menuitem.viewmodel';

export class MegaMenuMapper extends BaseMapper {
    public static mapToModel(response:  MegamenuResponse): MegamenuViewModel {
       
        
        const model: MegamenuViewModel = new MegamenuViewModel();
        this.autoMap(response, model);
      
        
        return model;
    }

    public static mapToModels(responses: MegamenuResponse[]): MegamenuViewModel[] {
        const models: MegamenuViewModel[] = [];
        if (responses) {
            for (const response of responses) {
                models.push(this.mapToModel(response));
            }
        }
        return models;
    }
}
 